import Phaser from 'phaser';

import Player from '../classes/player.js';
import Spawner from '../classes/utils/spawner.js';
import AttackManager from '../classes/utils/attack.js';
import LevelUpModal from '../ui/LevelUpModal.js';
import monsterData from '../classes/monsters/monsterData.js';

export default class GameState extends Phaser.Scene {
    constructor() {
        super({ key: 'GameState' });
    }

    create() {
        this.bgm = this.sound.add('bard_bgm', { loop: true });
        this.bgm.play();
    
        // Global mute state
        this.isMuted = false;
    
        // Calculate position for the sound icon
        const gameWidth = this.scale.width;
        const expBarHeight = 70; // Adjust this to match the height of your EXP bar
        const padding = 10; // Space between the EXP bar and the sound icon
    
        // Create the sound icon
        this.soundIcon = this.add.image(gameWidth - 50, expBarHeight + padding, 'soundIcon')
            .setInteractive()
            .setDepth(2);
    
        // Add an event listener to toggle sound when clicked
        this.soundIcon.on('pointerdown', () => {
            this.isMuted = !this.isMuted;
            this.sound.mute = this.isMuted; // Mute/unmute all sounds globally
    
            // Update the icon
            const newTexture = this.isMuted ? 'silentIcon' : 'soundIcon';
            this.soundIcon.setTexture(newTexture);
        });
    
        // Reposition sound icon on resize
        this.scale.on('resize', (gameSize) => {
            const { width } = gameSize;
            this.soundIcon.setPosition(width - 50, expBarHeight + padding);
        });
        this.soundIcon.setScrollFactor(0);
    
        // Set up sound effects
        this.monsterHitSound = this.sound.add('monsterHitSound');
        this.monsterHitSound.setVolume(1);
    
        this.collectGemSound = this.sound.add('collectGemSound');
        this.collectGemSound.setVolume(1);
    
            
        // Create the tilemap and tilesets
        const map = this.make.tilemap({ key: "pipoya_map", tileWidth: 40, tileHeight: 40 });
        const tileset1 = map.addTilesetImage('tiles1', 'tiles');
        const tileset2 = map.addTilesetImage('tiles2', 'tiles_at');
        const tileset3 = map.addTilesetImage('uncollidable', 'tiles_at');

        // Add layers
        this.layer1 = map.createLayer('Tile Layer 1', [tileset1, tileset2, tileset3], 0, 0);
        this.layer2 = map.createLayer('Tile Layer 2', [tileset1, tileset2, tileset3], 0, 0);
        this.layer3 = map.createLayer('Bridges', [tileset1, tileset2], 0, 0);

        this.layer2.setCollisionByProperty({ collides: true });

        // Create the player sprite in the middle of the screen
        this.player = new Player(this, 400, 300, 'hero');
        this.player.enableTouchInput() 
        // Set up collision between the player and layer2 (the tree layer)
        this.physics.add.collider(this.player, this.layer2);

        this.player.exp = 0;               // EXP for the current level
        this.player.totalExp = 0;         // Total EXP across all levels
        this.player.level = 1;            // Current level
        this.player.expRequired = this.calculateExpRequired(this.player.level);

        // EXP Bar Setup (Full Screen Width with Padding)
        this.padding = 10; // Save padding for reuse
        this.createUI();

        // Set up camera to follow the player
        this.cameras.main.startFollow(this.player);
        this.cameras.main.setBounds(0, 0, map.widthInPixels, map.heightInPixels);
        this.cameras.main.roundPixels = true;

        // Restrict player movement to the map bounds
        this.physics.world.setBounds(0, 0, map.widthInPixels, map.heightInPixels);

        // Input Setup
        this.cursors = this.input.keyboard.createCursorKeys();
        this.wasd = this.input.keyboard.addKeys({
            up: Phaser.Input.Keyboard.KeyCodes.W,
            down: Phaser.Input.Keyboard.KeyCodes.S,
            left: Phaser.Input.Keyboard.KeyCodes.A,
            right: Phaser.Input.Keyboard.KeyCodes.D,
        });
        // Laser, Monster, and Spawner Setup
        this.lasers = this.physics.add.group({ defaultKey: 'laser', maxSize: 100 });
        this.monsters = this.physics.add.group();
        this.spawner = new Spawner(this, this.monsters, this.player);

        // Timer for Spawning Monsters
        this.time.addEvent({
            delay: 2000,
            callback: () => {this.spawner.spawnMonster()},
            loop: true,
        });

        // Gems Setup
        this.gemGroup = this.physics.add.group();
        this.physics.add.overlap(this.player, this.gemGroup, this.player.collectGem, null, this);

        // Monster Collision
        this.physics.add.overlap(this.player, this.monsters, this.hitPlayer, null, this);

        // Attack Manager Initialization
        this.attackManager = new AttackManager(this, this.player, this.monsters);
        this.attackManager.initialize(); // AttackManager handles its timers
        this.levelUpModal = new LevelUpModal(this);

        // Pause the game and open the level-up modal at the start
        this.physics.pause();
        this.time.paused = true;
        this.levelUpModal.show();
        this.cameras.main.setBounds(0, 0, map.widthInPixels, map.heightInPixels);

        // Listen to the resize event
        this.scale.on('resize', this.onResize, this);
    }


    createUI() {
        const screenWidth = this.cameras.main.width;
        const barHeight = 20;
        const maxBarWidth = screenWidth - 2 * this.padding;
    
        // Calculate font size based on screen width
        const fontSize = Math.max(16, Math.min(36, screenWidth / 20)); // Adjust the divisor (20) to control scaling
    
        // Create the background bar (rounded edges)
        this.expBarBg = this.add.graphics();
        this.expBarBg.fillStyle(0x555555, 1);
        this.expBarBg.fillRoundedRect(this.padding, 20, maxBarWidth, barHeight, barHeight / 2);
        this.expBarBg.setScrollFactor(0);
    
        // Create the filled bar (rounded edges)
        this.expBar = this.add.graphics();
        this.expBar.setScrollFactor(0);
    
        // Draw the filled bar only if there's progress
        const initialWidth = 0; // Start with no progress
        if (initialWidth > 0) {
            this.expBar.fillStyle(0x4c9fd9, 1);
            this.expBar.fillRoundedRect(this.padding, 20, initialWidth, barHeight, barHeight / 2);
        }
        this.expBarBg.setDepth(2);  // Set a high depth for UI elements
    
        // Score Text (Total EXP)
        this.scoreText = this.add.text(screenWidth / 2, 80, 'Score: 0', {
            fontSize: `${fontSize}px`,
            fontFamily: 'Comic Sans MS',
            fill: '#ffffff',
        }).setOrigin(0.5).setScrollFactor(0);
    
        // Level Text
        this.levelText = this.add.text(this.padding, 80, `Level: 1`, {
            fontSize: `${fontSize}px`,
            fontFamily: 'Comic Sans MS',
            fill: '#ffffff',
        }).setOrigin(0, 0.5).setScrollFactor(0);
    
        this.scoreText.setDepth(2);
        this.levelText.setDepth(2);
    }
    
    onResize(gameSize) {
        const { width, height } = gameSize;
        const barHeight = 20;
        const maxBarWidth = width - 2 * this.padding;
    
        // Resize the LevelUpModal
        this.levelUpModal.onResize(width, height);
    
        // Update the camera size to match the new window size
        this.cameras.main.setSize(width, height);
    
        // Clear and redraw the background bar
        this.expBarBg.clear();
        this.expBarBg.fillStyle(0x555555, 1);
        this.expBarBg.fillRoundedRect(this.padding, 20, maxBarWidth, barHeight, barHeight / 2);
    
        // Update filled bar based on current EXP
        const expProgress = this.player.exp / this.player.expRequired;
        const filledBarWidth = maxBarWidth * Math.min(expProgress, 1);
        this.expBar.clear();
        if (filledBarWidth > 0) {
            this.expBar.fillStyle(0x4c9fd9, 1);
            this.expBar.fillRoundedRect(this.padding, 20, filledBarWidth, barHeight, barHeight / 2);
        }
    
        // Adjust font size based on new width
        const fontSize = Math.max(16, Math.min(36, width / 20));
        this.scoreText.setStyle({ fontSize: `${fontSize}px` });
        this.levelText.setStyle({ fontSize: `${fontSize}px` });
    
        // Update positions
        this.scoreText.setPosition(width / 2, 80);
        this.levelText.setPosition(this.padding, 80);
    }
                

    hitPlayer(player, monster) {
        monster.tryToAttack(player, this);
    }

    update() {
        this.player.move(this.cursors, this.wasd);

        this.monsters.getChildren().forEach(monster => {
            this.spawner.followPlayer(monster);
        });

        this.gemGroup.getChildren().forEach(gem => {
            if (!gem.canBeCollected) return;

            const distance = Phaser.Math.Distance.Between(this.player.x, this.player.y, gem.x, gem.y);
            if (distance < 100) {
                const angle = Phaser.Math.Angle.Between(gem.x, gem.y, this.player.x, this.player.y);
                gem.body.setVelocity(Math.cos(angle) * 500, Math.sin(angle) * 500);
            }
        });

        if (this.gemGroup.getLength() > 1500) {
            const oldestGem = this.gemGroup.getFirstAlive();
            if (oldestGem) oldestGem.destroy();
        }
        this.spawner.update(); // Call the Spawner's update
    }

    calculateExpRequired(level) {
        return Math.floor(50 + 50 * Math.pow(1.15, level));
    }

    updateExpBar() {
        const padding = 10; // Same padding value as before
        const barHeight = 20; // Height of the EXP bar
    
        const expProgress = this.player.exp / this.player.expRequired;
        const maxBarWidth = this.cameras.main.width - 2 * padding;
        const filledBarWidth = maxBarWidth * Math.min(expProgress, 1);
    
        // Clear and redraw the filled EXP bar only if the width is greater than 0
        this.expBar.clear();
        if (filledBarWidth > 0) {
            this.expBar.fillStyle(0x4c9fd9, 1);
            this.expBar.fillRoundedRect(padding, 20, filledBarWidth, barHeight, barHeight / 2);
        }
        this.expBar.setDepth(2);  // Set a high depth for UI elements
        this.expBarBg.setDepth(2);  // Set a high depth for UI elements

        // Update score and level text
        this.scoreText.setText(`Score: ${this.player.totalExp}`);
        this.levelText.setText(`Level: ${this.player.level}`);
    
        // Handle leveling up if EXP exceeds the required amount
        if (this.player.exp >= this.player.expRequired) {
            this.levelUp();
        }
    }
        
    levelUp() {
        
        this.player.level += 1;
        this.player.exp -= this.player.expRequired;
        this.player.expRequired = this.calculateExpRequired(this.player.level);
        
        // Pause and show modal
        this.physics.pause();
        this.time.paused = true;
        this.scene.pause(); // Pause the game

        // Show the level-up popup and define the callback to resume the game
            this.physics.resume();  // Resume the game
            this.time.paused = false;
            // Additional logic for after level-up can go here (e.g., spawning monsters)
            this.game.reactSetScore(this.player.totalExp)
            // Notify React that the question modal should open
            if (this.game.openQuestion) {
              this.game.openQuestion('levelUp'); // Call the function passed from React to open the modal
              this.started=true;
            }
            // Spawn boss monsters if required
            const spawnX = Phaser.Math.Between(100, this.physics.world.bounds.width - 100);
            const spawnY = Phaser.Math.Between(100, this.physics.world.bounds.height - 100);
    
            switch (this.player.level) {
                case 15:
                    this.spawner.spawnSingleMonster([{ type: 'boss01', movement: 'following' }], spawnX, spawnY);
                    break;
                case 30:
                    this.spawner.spawnSingleMonster([{ type: 'boss02', movement: 'following' }], spawnX, spawnY);
                    break;
                case 45:
                    this.spawner.spawnSingleMonster([{ type: 'boss03', movement: 'following' }], spawnX, spawnY);
                    break;
                case 60:
                    this.spawner.spawnSingleMonster([{ type: 'boss04', movement: 'following' }], spawnX, spawnY);
                    break;
                default:
                    break;
            }
    
    
        

    }
    openLevelUpModal(){
        this.levelUpModal.show();    

    }
}
