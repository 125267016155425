const skillConfigs = {
    laser: {
        attackSpeed: 700,
        damage: 10,
        range: 700,
        targets: 5,
        numLasers: 1,
    },
    ringOfFire: {
        attackSpeed: 1500,
        damage: 20,
        range: 2,
    },
    icicle: {
        attackSpeed: 500, 
        damage: 20,
        range: 1000,
        speed: 900,
        numIcicles: 1, // Starts with 1 and upgrades to more
    },
    shuriken: {
        attackSpeed: 700,     // Cooldown between shuriken attacks
        damage: 30,          // Damage dealt by each shuriken
        range: 1000,           // Maximum distance a shuriken can travel
        numShurikens: 2,      // Initial number of shurikens (can scale up)
    },
    tornado: {
        attackSpeed: 1000,  // Cooldown between tornado attacks
        damage: 15,        // Damage dealt by each tornado
        range: 500,         // Maximum distance a tornado can travel
        speed: 300,         // Speed of the tornado movement
        knockbackForce: 300,  // Force of the knockback applied to enemies
        targets: 5,         // Maximum number of monsters a tornado can hit
        size:2,
        numTornadoes: 1,    // Number of tornadoes to shoot at once (this can be upgraded)
    },
    spiritBall: {
        attackSpeed: 4000,    // Cooldown between SpiritBall attacks
        damage: 30,         // Damage dealt by each SpiritBall
        range: 300,          // Maximum distance a SpiritBall can travel
        size: 6,             // Initial size of the spirit ball (upgradable)
        speed: 500,          // Speed at which the SpiritBall moves
    },
    boomerang: { 
        attackSpeed: 2000, // Cooldown between throws
        damage: 10,       // Damage dealt by each boomerang
        range: 600,        // Maximum distance before returning
        size: 3,           // Scale of the boomerang
        speed: 400,        // Speed of the boomerang
    },    
    // superStar: {
    //     attackSpeed: 1000,   // Cooldown between SuperStar attacks
    //     damage: 250,         // Damage dealt by each SuperStar
    //     range: 6,          // Radius of the effect
    // },

        
};

export default skillConfigs;
