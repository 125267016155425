import Phaser from 'phaser';
import Coin from '../Coin'; // Ensure you have the Coin class imported

class Trap extends Phaser.GameObjects.Container {
  constructor(scene, x, y) {
    super(scene, x, y);

    // Add the container to the scene
    scene.add.existing(this);
    scene.physics.world.enable(this);
    scene.groups.obstacles.add(this);

    this.distance = 260;

    // Create top piece
    this.topPiece = scene.add.sprite(0, 0, 'atlas', 'trap_top');
    scene.physics.add.existing(this.topPiece);
    this.topPiece.body.setSize(this.topPiece.width, this.topPiece.height);
    this.topPiece.body.setAllowGravity(false);
    this.scene.groups.obstacles.add(this.topPiece); // Add to obstacles group
    // Create bottom piece
    this.bottomPiece = scene.add.sprite(0, this.distance, 'atlas', 'trap_bottom');
    scene.physics.add.existing(this.bottomPiece);
    this.bottomPiece.body.setSize(this.bottomPiece.width, this.bottomPiece.height);
    this.bottomPiece.body.setAllowGravity(false);
    this.scene.groups.obstacles.add(this.bottomPiece); // Add to obstacles group

    scene.physics.add.collider(this.topPiece, scene.bird, this.dispose, null, this);
    scene.physics.add.collider(this.bottomPiece, scene.bird, this.dispose, null, this);

    // // Add pieces to the container
  
    // Set initial positions
    this.topPiece.x = x;
    this.topPiece.y = y;
    this.bottomPiece.x = x;
    this.bottomPiece.y = y + this.distance;

    // Set velocities
    // this.topPiece.body.setVelocityX(-scene.speed * 60);
    // this.bottomPiece.body.setVelocityX(-scene.speed * 60);

    // Generate coins
    this.generateCoins();

  }
  update() {
    this.topPiece.x -= this.scene.speed;
    this.bottomPiece.x -= this.scene.speed;

    // this.topPiece.body.x -= this.scene.speed;
    // this.bottomPiece.body.x -= this.scene.speed;

    if (this.topPiece.x < -this.topPiece.width) {
      this.dispose();
    }
    if (this.topPiece.x < -1400) {
      this.dispose();
    }

  }

  dispose(body1,body2) {
    if(body2){
      body2.crash(body2,body1);
    }
    this.topPiece.destroy();
    this.bottomPiece.destroy();
    this.destroy();
  }

  generateCoins() {
    const coins = Phaser.Math.Between(1, 3);
    const width = 58;
    const xx = this.topPiece.x - (coins - 1) * 0.5 * width;
    const yy = this.topPiece.y + 130;

    for (let i = 0; i < coins; i++) {
      this.scene.groups.coins.add(new Coin(this.scene, xx + i * width, yy));
    }
  }
}

export default Trap;
