import Phaser from 'phaser';

export default class Monster extends Phaser.Physics.Arcade.Sprite {
    constructor(scene, x, y, texture, config) {
        super(scene, x, y, texture);
    
        // Add monster to the scene
        scene.add.existing(this);
        scene.physics.add.existing(this);
    
        // Set default properties using config
        this.setStats(config);
    
        // Use size and scale from the config
        const { scale = 0.2, size = { width: 100, height: 100 },offset={x:0,y:0} } = config;

        this.setScale(scale); // Dynamically set the scale
            // Adjust the origin after scaling (Optional)
        this.setOrigin(0.5, 0.5); 

        this.setSize(size.width, size.height); // Dynamically set the size
        this.setOffset(
            offset.x, // Adjust offset to center by default
            offset.y
        );
        // Initialize attack cooldown
        this.canAttack = true;
        this.attackCooldown = 500; // Default 1-second cooldown
    
        // Create health bar (Graphics object to draw the health bar)
        // this.healthBar = scene.add.graphics();
        // this.updateHealthBar(); // Initial health bar draw
    }
    
    setStats({ speed = 100, health = 10, damage = 1 ,exp = 10} = {}) {
        this.speed = speed;
        this.health = health;
        this.maxHealth = health; // Store max health for health bar scaling
        this.damage = damage;
        this.exp = exp
    }

    takeDamage(amount, isCritical = false) {
        this.blinkWhite();
        this.health -= amount;
        // Show damage text above the monster
        this.showDamageText(amount, isCritical);
        this.scene.monsterHitSound.play();  // This will use the volume you set

        // Blink white when hit
    
        // Check if the monster is dead
        if (this.health <= 0) {
            this.die();
        } else {
            // this.updateHealthBar(); // Update health bar if still alive
        }
    }
    blinkWhite() {
        const originalAlpha = this.alpha;
    
        this.setTintFill(0xffffff);

        // Revert back to the original texture after a short delay
        this.scene.time.delayedCall(100, () => {
            this.clearTint();
        });
    }
         
    // Displays damage numbers (white for normal, orange for critical hits)
    showDamageText(amount, isCritical) {
        // Adjust font size based on whether the hit is critical
        const fontSize = isCritical ? '24px' : '18px'; // Larger font for critical hits
        const color = isCritical ? '#FFA500' : '#FFFFFF'; // Orange for crit, white for normal
        
        // Create the damage text
        const text = this.scene.add.text(this.x, this.y - 30, `${amount}`, {
            fontSize: fontSize, // Dynamic font size
            color: color,
            fontStyle: isCritical ? 'bold' : 'normal', // Bold for critical hits
        }).setOrigin(0.5);
    
        // Tween the text upwards and fade out
        this.scene.tweens.add({
            targets: text,
            y: this.y - 50, // Move upward
            alpha: 0, // Fade out
            duration: 1500, // Duration of the animation
            onComplete: () => text.destroy(), // Destroy the text after the tween
        });
    }
        
    die() {
        this.dropGem(); // Drop a gem when the monster dies
        // this.healthBar.destroy(); // Destroy health bar
        this.destroy(); // Destroy the monster
    }
    
    dropGem() {
        const exp = this.exp;
    
        // Calculate the number of gems to drop for each type
        let numGem01 = 0, numGem02 = 0, numGem03 = 0;

        if (exp < 200) {
            numGem01 = Math.floor(exp / 10);
        } else if (exp < 1000) {
            numGem02 = Math.floor(exp / 50);
        } else {
            numGem03 = Math.floor(exp / 100);
        }

        // Helper function to drop specific gems
        const dropSpecificGem = (gemType, count) => {
            const spreadFactor = Math.max(1, Math.sqrt(count) * 0.5);
            for (let i = 0; i < count; i++) {
                const gem = this.scene.physics.add.sprite(this.x, this.y, gemType, 0);
                this.scene.gemGroup.add(gem);
    
                const angle = Phaser.Math.FloatBetween(0, Math.PI * 2);
                const speed = Phaser.Math.Between(100, 300) * spreadFactor;
    
                gem.body.setVelocity(Math.cos(angle) * speed, Math.sin(angle) * speed);
                gem.body.setBounce(0.5);
                gem.body.setDrag(1000, 1000);
    
                // Temporarily disable collection for a brief deployment delay
                gem.canBeCollected = false;
                // gem.play('gem_spin');
                this.scene.time.delayedCall(100, () => {
                    if (gem.body) {
                        gem.body.setVelocity(0, 0); // Stop the gem's movement
                        gem.canBeCollected = true;
                    }
                });
            }
        };
    
        // Drop gems based on calculated counts
        if (numGem01 > 0) dropSpecificGem('gem01', numGem01);
        if (numGem02 > 0) dropSpecificGem('gem02', numGem02);
        if (numGem03 > 0) dropSpecificGem('gem03', numGem03);
    }
    //     updateHealthBar() {
    //     const barHeight = 5; // Height of the health bar
    
    //     // Calculate the position based on the sprite's physics body
    //     const body = this.body; // Reference to the physics body
    //     if (!body) return; // Safety check
    
    //     const barWidth = body.width; // Use the width of the physics body
    //     const barX = body.x; // Position at the start of the body
    //     const barY = body.y + body.height + 5; // Just below the body
    
    //     // Clear previous health bar
    //     this.healthBar.clear();
    
    //     // Draw the health bar background (red)
    //     this.healthBar.fillStyle(0xFF0000, 1);
    //     this.healthBar.fillRect(barX, barY, barWidth, barHeight);
    
    //     // Draw the health fill (green) based on current health
    //     const healthWidth = (this.health / this.maxHealth) * barWidth;
    //     this.healthBar.fillStyle(0x00FF00, 1);
    //     this.healthBar.fillRect(barX, barY, healthWidth, barHeight);
    // }
        
    // Method to update health bar position every frame
    update() {
        // Ensure the health bar follows the monster
        // this.update();

    }

    tryToAttack(target, scene) {
        if (!this.canAttack) return; // Skip attack if on cooldown
        this.applyKnockback(target);

        // Perform the attack logic (e.g., apply damage to the player)
        target.takeDamage(this.damage);

        // Trigger the attack cooldown
        this.canAttack = false;
        scene.time.delayedCall(this.attackCooldown, () => {
            this.canAttack = true;
        });
    }

    applyKnockback(target) {
        const knockbackForce = 100;
        const angle = Phaser.Math.Angle.Between(this.x, this.y, target.x, target.y);
    
        // Reset velocity and knockback flag before applying knockback
        this.body.setVelocity(0, 0);
        this.knockback = false;
    
        this.body.velocity.x = -Math.cos(angle) * knockbackForce;
        this.body.velocity.y = -Math.sin(angle) * knockbackForce;
    
        this.knockback = true;
    
        // Clear any existing knockback timer
        if (this.knockbackTimer) {
            this.knockbackTimer.remove(false);
        }
    
        // Set a new knockback timer
        this.knockbackTimer = this.scene.time.delayedCall(500, () => {
            if (!this.body) return; // Prevent errors if the monster has been destroyed
            this.knockback = false;
            this.body.setVelocity(0, 0); // Stop knockback
        });
    }
    
    respawnKnockBack(player) {
        const knockbackForce = 500;
        const angle = Phaser.Math.Angle.Between(this.x, this.y, player.x, player.y);
    
        // Reset velocity and knockback flag before applying knockback
        this.body.setVelocity(0, 0);
        this.knockback = false;
    
        this.body.velocity.x = -Math.cos(angle) * knockbackForce;
        this.body.velocity.y = -Math.sin(angle) * knockbackForce;
    
        this.knockback = true;
    
        // Clear any existing knockback timer
        if (this.knockbackTimer) {
            this.knockbackTimer.remove(false);
        }
    
        // Set a new knockback timer
        this.knockbackTimer = this.scene.time.delayedCall(500, () => {
            if (!this.body) return; // Prevent errors if the monster has been destroyed
            this.knockback = false;
            this.body.setVelocity(0, 0); // Stop knockback
        });
    }
    }
