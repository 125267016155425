import React from 'react';
import { Container, Row, Col, Card, FormGroup, Label, Input } from 'reactstrap';
import HeroOfDawnPreview from '../../assets/images/PreviewFolder/HeroOfDawnPreview.png';

const HungryHeroSettings = ({ questionset, timer, onTimerInputChange, onTimerChange, onCreateRoom }) => {
  return (
    <Container className="h-100"fluid>
      <Row className="h-100">
        <Col lg="3"></Col>
        <Col md="12" lg="6">
          <Card className="text-center">
            <Container className="h-100">
              <Row>
                <Col lg="12">
                  <h1 className='titan-one-regular'>Hero of Dawn</h1>
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <img className="mw-100 settings-game-image" src={HeroOfDawnPreview} alt="Hungry Hero" />
                </Col>
              </Row>
              <Row className="h-100 p-2">
                
                <Col md="6" className="border-right-md">
                  <Container className="text-center nunito">
                    <Row className="text-center">
                      <Col xs="12">
                        <h3 className="text-center mb-3">{questionset.title}</h3>
                      </Col>
                    </Row>
                    <Row>
                        {questionset.image && (
                          <Col>
                            <img className="setup-image mb-3" src={questionset.image} alt="Question Set" />
                          </Col>
                        )}
                        {questionset.description && (
                          <Col className='vertical-center'>
                            <p className="mb-3">{questionset.description}</p>
                          </Col>
                        )}
                    </Row>
                  </Container>
                </Col>
                        <hr></hr>
                <Col xs="12" md="6" className="mb-3 vertical-center justify-content-center nunito">
                  <Container>
                    <Row>
                      <Col xs="12">
                        <FormGroup>
                        <Label htmlFor="timer"className="font-weight-bold">Time (minutes) (3-30 min)</Label>
                        <Input
                            type="number"
                            id="timer"
                            min="3"
                            max="30"
                            value={timer}
                            onChange={onTimerInputChange} // Handle typing input
                            onBlur={onTimerChange} // Validate on blur (when the user finishes)
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12">
                        <button
                          onClick={onCreateRoom}
                          className="btn btn-rounded nav-btn-light-blue navbar-btn"
                        >
                          Start
                        </button>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default HungryHeroSettings;
