import { useState, useEffect, useRef } from "react";
import Phaser from 'phaser';
import GameState from './states/GameState.js';
import MainMenuState from './states/MainMenuState.js';
import PreloadState from './states/PreloadState.js';
import './style.css';
import QuizModal from '../../components/QuizModal/index.js'
import API from '../../utils/API.js'
import FinalScoreWaiting from "../../components/FinalScoreWaiting/index.js";
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1)); // Random index from 0 to i
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
  return array;
}

const HeroOfDawn = (props) => {
  let game;

  const gameContainerRef = useRef(null);
  const [phaserGame, setPhaserGame] = useState(null); // State to store the Phaser game instance

  //below is used for question answering mechanics~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  const { handleScoreChange,isGameOver, getReport} = props; // Destructure handleScoreChange from props
  const [score, setScore] = useState(0); // React state for score
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const[questionsPool,setQuestionsPool] = useState([]);
  const [questionSet, setQuestionSet] = useState({});
  const[nextQuestion,setNextQuestion] = useState({});
  const [answeredQuestions, setAnsweredQuestions] = useState(new Set());
  const [triggerReason, setTriggerReason] = useState(null);
  const [quizReport, setQuizReport] = useState({
    questions: [],       // Array to store questions and their data
    totalCorrect: 0,     // Counter for correct answers
    questionsAnswered: 0 // Counter for total questions answered
  });

useEffect(()=>{
    if(isModalOpen){
      if(handleScoreChange){
        handleScoreChange(score);
      }    
    }
  },[isModalOpen])

  useEffect(() => {
    if (isGameOver) {
      let gameState = phaserGame.scene.getScene('GameState');
      if (gameState) {
        handleScoreChange(gameState.player.totalExp);
      }
      getReport(quizReport); 
      handleGameEnd(); // Call the function when isGameOver becomes true
    }
  }, [isGameOver]);

  const handleGameEnd = () => {
    // Implement your game end logic here
    if (phaserGame) {
      // Destroy the Phaser game instance when the game is over
      phaserGame.destroy(true);
      setPhaserGame(null); // Clear the Phaser game instance from the state
    }
    
  };

  const handleAnswer = (answer) => {
    // Resume the game or proceed based on the answer
    if (answer === 'true') {
      setIsModalOpen(false);
  
      if (phaserGame) {
        // Access the scene manager and restart the scene
        const gameState = phaserGame.scene.getScene('GameState');
        if (gameState) {
            if(triggerReason){
              if(gameState.triggerReason="levelUp"){
                // console.log(gameState)
                gameState.openLevelUpModal();
              }

            }
            gameState.scene.resume('GameState');
            setTriggerReason(null)

          phaserGame.questionOpened = false;
        }
      }
    }
    
    // Log answered question
  
    // Use a functional state update to add the answered question
    setAnsweredQuestions(prev => {
      const newAnsweredQuestions = new Set(prev); // Create a new Set from previous state
      newAnsweredQuestions.add(nextQuestion.id); // Add the new answered question ID  
      // Select the next question based on the updated answered questions
      setNextQuestion(selectNextQuestion(newAnsweredQuestions)); // Pass the updated Set to selectNextQuestion
      return newAnsweredQuestions; // Return the updated Set
    });
  };
  
  const selectNextQuestion = (updatedAnsweredQuestions) => {
    // Filter out answered questions based on their IDs
    const unansweredQuestions = questionsPool.filter(
      question => !updatedAnsweredQuestions.has(question.id) // Use Set's has method
    );
  
    // If all questions have been answered, reset the answered questions
    if (unansweredQuestions.length === 0) {
      setAnsweredQuestions(new Set()); // Reset answered questions
      return questionsPool[Math.floor(Math.random() * questionsPool.length)]; // Select a random question
    }
    // Select a random question from unanswered ones
    return unansweredQuestions[Math.floor(Math.random() * unansweredQuestions.length)];
  };
    
useEffect(()=>{
  if(props.questionSet){
    setQuestionSet(props.questionSet);
    const shuffledQuestions = shuffleArray(props.questionSet.Questions); // Use slice to avoid mutating the original array
    let cleanedQuestions = []
    for (let i = 0; i < shuffledQuestions.length; i++) {
      if(shuffledQuestions[i].question){
        cleanedQuestions.push(shuffledQuestions[i])
      } 
    }
    setQuestionsPool(cleanedQuestions)
    const randomIndex = Math.floor(Math.random() * cleanedQuestions.length);
    // Set the selected question
    setNextQuestion(cleanedQuestions[randomIndex]);
    API.addPlayCount(props.questionSet.id)

  }else{
    
      API.getQuestionSet(10).then(res=>{
        setQuestionSet(res.data);
        const shuffledQuestions = shuffleArray(res.data.Questions); // Use slice to avoid mutating the original array
        let cleanedQuestions = []
        for (let i = 0; i < shuffledQuestions.length; i++) {
          if(shuffledQuestions[i].question){
            cleanedQuestions.push(shuffledQuestions[i])
          } 
        }
        setQuestionsPool(cleanedQuestions)
        const randomIndex = Math.floor(Math.random() * cleanedQuestions.length);
        // Set the selected question
        setNextQuestion(cleanedQuestions[randomIndex]);
        // API.addPlayCount(10)
      }).catch(err=>{
        console.log(err)
    })
  }
},[])
  //above is used for question answering mechanics~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  useEffect(() => {
    document.body.style.backgroundColor = 'black';

    const gameConfig = {
      type: Phaser.AUTO,
      width: window.innerWidth,   // Full width of the window
      height: window.innerHeight, // Full height of the window
      parent: gameContainerRef.current, // Reference the DOM node
      scene: [PreloadState,MainMenuState, GameState],
      physics: {
        default: 'arcade',
        arcade: {
          debug: false,
        }
      },
      pixelArt: true, // Important for pixel art games
      scale: {
        mode: Phaser.Scale.RESIZE,  // Resize dynamically to fit the window
        autoCenter: Phaser.Scale.CENTER_BOTH,  // Center the game
      },      
      renderer: {
        pixelArt: true,
        antialias: false,  // Disable antialias for pixel-perfect rendering
      },

    };

    game = new Phaser.Game(gameConfig);
    game.reactSetScore = setScore;
    // game.reactScore = score;
    game.openQuestion = openQuestion; // Expose modal control to Phaser

    setPhaserGame(game);

    // Clean up on unmount
    return () => {
      if (phaserGame) {
        phaserGame.destroy(true);
        setPhaserGame(null);
      }
      document.body.style.backgroundColor = ''; // Set back to default
    };
  }, []); // Empty dependency array to run only on mount and unmount

  const openQuestion= (triggerReason)=>{
    setTriggerReason(triggerReason)
    setIsModalOpen(true);
    game.questionOpened = true
  }
  const recordAnswer = (answer) => {
    setQuizReport((prev) => {
      // Add the question and selected answer to the questions array
      const updatedQuestions = [
        ...prev.questions,
        { ...nextQuestion, selectedAnswer: answer }
      ];
  
      // Update totalCorrect if the answer is correct
      const isCorrect = answer.correctAnswer=== "true";
      return {
        ...prev,
        questions: updatedQuestions,
        questionsAnswered: prev.questionsAnswered + 1,
        totalCorrect: isCorrect ? prev.totalCorrect + 1 : prev.totalCorrect
      };
    });
  };

  return (
    <>        
    {isGameOver? 
      <div className="vertical-center waiting-room" style={{minHeight:"100vh", minWidth:"100vw"}}>
      <FinalScoreWaiting></FinalScoreWaiting>
      </div>    
      :
        <div style={{ overflow: 'hidden', background: 'black', height: '100vh', width: '100vw' }} className="">
          <>
          <div ref={gameContainerRef} id="survivor" />
          <QuizModal 
            isOpen={isModalOpen} 
            onRequestClose={() => setIsModalOpen(false)} 
            onAnswer={handleAnswer} 
            question={nextQuestion}
            recordAnswer={recordAnswer}
          />
          </>            
        </div>
      }
    </>
  );
};

export default HeroOfDawn;
