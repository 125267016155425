import Phaser from 'phaser';

export default class Shuriken {
    constructor(scene, player, monsters, config) {
        this.scene = scene;           // Reference to the Phaser scene
        this.player = player;         // Player reference
        this.monsters = monsters;     // Monster group
        this.config = { ...config };  // Copy of the configuration
        this.type = 'shuriken';       // Identifier for this skill
        this.canShoot = true;         // Track cooldown state
        this.projectiles = this.scene.physics.add.group(); // Group for shuriken projectiles

        // Add collision handling for projectiles and monsters
        this.scene.physics.add.overlap(this.projectiles, this.monsters, this.handleHit, null, this);
    }

    update() {
        if (!this.canShoot) return;

        this.shoot();
        this.setCooldown();
    }

    shoot() {
        const angles = this.getAngles(); // Get angles for the current number of shurikens

        angles.forEach(angle => {
            // Create a new shuriken sprite
            const shuriken = this.scene.physics.add.sprite(this.player.x, this.player.y, 'shuriken01');
            shuriken.setScale(0.5); // Adjust visual scale if necessary
            this.projectiles.add(shuriken);

            // Rotate the shuriken to face the shooting angle
            shuriken.rotation = angle;

            // Set spinning effect
            shuriken.setAngularVelocity(360); // Adjust rotation speed (degrees per second) as desired

            // Move the shuriken in the specified direction
            const velocityX = Math.cos(angle) * 300;
            const velocityY = Math.sin(angle) * 300;
            shuriken.body.setVelocity(velocityX, velocityY);

            // Destroy the shuriken after traveling the maximum range
            const travelTime = (this.config.range / 300) * 1000;
            this.scene.time.delayedCall(travelTime, () => {
                if (shuriken.active) shuriken.destroy();
            });
        });
    }

    setCooldown() {
        this.canShoot = false;
        this.scene.time.delayedCall(this.config.attackSpeed, () => {
            this.canShoot = true;
        });
    }

    getAngles() {
        // Return an array of angles based on the number of shurikens
        const shurikenCount = this.config.numShurikens;
        const angleIncrement = (2 * Math.PI) / shurikenCount; // Evenly distribute angles
        const angles = [];

        for (let i = 0; i < shurikenCount; i++) {
            angles.push(i * angleIncrement);
        }

        return angles;
    }

    upgradeStat(stat, value) {
        if (this.config[stat] !== undefined) {
            this.config[stat] += value;
        }
    }

    handleHit(projectile, monster) {
        // Deal damage to the monster
        const damage = this.config.damage;
        monster.takeDamage(damage);

        // Destroy the projectile on collision
        projectile.destroy();
    }
}
