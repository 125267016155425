import Phaser from 'phaser';

export default class MainMenuState extends Phaser.Scene {
  constructor() {
    super({ key: 'MainMenuState' });
  }

  create() {
    const { width, height } = this.scale;

    // Create elements
    this.title = this.add.text(width / 2, height / 4, 'Hero of Dawn', {
      fontSize: '48px',
      fontFamily: 'Arial',
      fill: '#ffffff',
    }).setOrigin(0.5);

    this.instructions = this.add.text(width / 2, height / 2,
      'Instructions:\n- Use arrow keys, WASD, or tap/click to move your character.\n- Avoid enemies and survive as long as possible.\n- Collect gems to gain experience and level up your abilities.', {
      fontSize: '24px',
      fontFamily: 'Arial',
      fill: '#ffffff',
      align: 'center',
    }).setOrigin(0.5);

    this.startText = this.add.text(width / 2, (3 * height) / 4, 'Click to Start', {
      fontSize: '32px',
      fontFamily: 'Arial',
      fill: '#00ff00',
    }).setOrigin(0.5);

    // Make the start text interactive
    this.startText.setInteractive();
    this.startText.on('pointerdown', () => {
      this.scene.start('GameState'); // Start the game state
    });

    // Listen for resize events
    this.scale.on('resize', this.onResize, this);
  }

  onResize(gameSize) {
    const { width, height } = gameSize;

    // Reposition elements based on new size
    this.title.setPosition(width / 2, height / 4);
    this.instructions.setPosition(width / 2, height / 2);
    this.startText.setPosition(width / 2, (3 * height) / 4);
  }

  destroy() {
    // Cleanup event listeners to prevent memory leaks
    this.scale.off('resize', this.onResize, this);
  }
}
