const monsterData = {
    bat01: { texture: 'bat01', speed: 120, health: 30, damage: 10, exp: 10, scale: 0.2, size: { width: 130, height: 110 }, offset: { x: 180, y: 150 }},
    bat02: { texture: 'bat02', speed: 140, health: 200, damage: 20, exp: 30, scale: 0.2, size: { width: 130, height: 110 },offset: { x: 180, y: 150 }},
    bat03: { texture: 'bat03', speed: 150, health: 300, damage: 30, exp: 40, scale: 0.2, size: { width: 130, height: 110 },offset: { x: 180, y: 150 }},
    snake01: { texture: 'snake01', speed: 100, health: 40, damage: 8, exp: 10, scale: 0.2, size: { width: 150, height: 200 },offset: { x: 180, y: 230 }},
    snake02: { texture: 'snake02', speed: 110, health: 50, damage: 10, exp: 40, scale: 0.2, size: { width: 150, height: 200 },offset: { x: 180, y: 230 }},
    snake03: { texture: 'snake03', speed: 120, health: 60, damage: 12, exp: 40, scale: 0.2, size: { width: 150, height: 200 },offset: { x: 180, y: 230 }},
    treant01: { texture: 'treant01', speed: 100, health: 200, damage: 20, exp: 20, scale: 0.2, size: { width: 350, height: 400 },offset: { x: 70, y: 80 }},
    treant02: { texture: 'treant02', speed: 150, health: 200, damage: 22, exp: 30, scale: 0.2, size: { width: 350, height: 400 },offset: { x: 70, y: 80 }},
    treant03: { texture: 'treant03', speed: 150, health: 400, damage: 20, exp: 250, scale: 0.2, size: { width: 350, height: 400 },offset: { x: 70, y: 80 }},
    shroom01: { texture: 'shroom01', speed: 90, health: 200, damage: 8, exp: 20, scale: 0.2, size: { width: 280, height: 270 },offset: { x: 90, y: 180 }},
    shroom02: { texture: 'shroom02', speed: 95, health: 200, damage: 10, exp: 40, scale: 0.2, size: { width: 280, height: 270 },offset: { x: 90, y: 180 }},
    shroom03: { texture: 'shroom03', speed: 100, health: 400, damage: 12, exp: 40, scale: 0.2, size: { width: 280, height: 270 },offset: { x: 90, y: 180 }},
    slime01: { texture: 'slime01', speed: 80, health: 30, damage: 5, exp: 40, scale: 0.2, size: { width: 120, height: 120 },offset: { x: 180, y: 310 }},
    slime02: { texture: 'slime02', speed: 85, health: 200, damage: 20, exp: 40, scale: 0.2, size: { width: 120, height: 120 },offset: { x: 180, y: 310 }},
    slime03: { texture: 'slime03', speed: 100, health: 300, damage: 7, exp: 80, scale: 0.2, size: { width: 120, height: 120 },offset: { x: 180, y: 310 }},
    zombie01: { texture: 'zombie01', speed: 70, health: 600, damage: 12, exp: 40, scale: 0.2, size: { width: 160, height: 260 },offset: { x: 155, y: 160 }},
    zombie02: { texture: 'zombie02', speed: 75, health: 720, damage: 15, exp: 100, scale: 0.2, size: { width: 160, height: 260 },offset: { x: 155, y: 160 }},
    zombie03: { texture: 'zombie03', speed: 80, health: 1000, damage: 18, exp: 250, scale: 0.2, size: { width: 160, height: 260 },offset: { x: 155, y: 160 }},
    ogre01: { texture: 'ogre01', speed: 50, health: 300, damage: 25, exp: 20, scale: 0.2, size: { width: 240, height: 320 },offset: { x: 160, y: 140 }},
    ogre02: { texture: 'ogre02', speed: 70, health: 600, damage: 30, exp: 30, scale: 0.2, size: { width: 240, height: 320 },offset: { x: 160, y: 140 }},
    ogre03: { texture: 'ogre03', speed: 90, health: 800, damage: 50, exp: 130, scale: 0.2, size: { width: 240, height: 320 },offset: { x: 160, y: 140 }},
    hatchling01: { texture: 'hatchling01', speed: 150, health: 300, damage: 20, exp: 30, scale: 0.2, size: { width: 160, height: 270 },offset: { x: 155, y: 160 }},
    hatchling02: { texture: 'hatchling02', speed: 150, health: 600, damage: 30, exp: 100, scale: 0.2, size: { width: 160, height: 270 },offset: { x: 155, y: 160 }},
    hatchling03: { texture: 'hatchling03', speed: 130, health: 1000, damage: 15, exp: 40, scale: 0.2, size: { width: 160, height: 270 },offset: { x: 155, y: 160 }},
    knight01: { texture: 'knight01', speed: 80, health: 250, damage: 20, exp: 30, scale: 0.2, size: { width: 210, height: 300 },offset: { x: 155, y: 130 }},
    knight02: { texture: 'knight02', speed: 100, health: 700, damage: 25, exp: 40, scale: 0.2, size: { width: 210, height: 300 },offset: { x: 155, y: 130 }},
    knight03: { texture: 'knight03', speed: 90, health: 1400, damage: 30, exp: 40, scale: 0.2, size: { width: 210, height: 300 },offset: { x: 155, y: 130 }},
    ogre11: { texture: 'ogre11', speed: 65, health: 800, damage: 50, exp: 400, scale: 0.2, size: { width: 350, height: 400 },offset: { x: 100, y: 70 }},
    ogre12: { texture: 'ogre12', speed: 75, health: 1200, damage: 70, exp: 800, scale: 0.2, size: { width: 350, height: 400 },offset: { x: 100, y: 70 }},
    ogre13: { texture: 'ogre13', speed: 90, health: 1800, damage: 90, exp: 1200, scale: 0.2, size: { width: 350, height: 400 },offset: { x: 100, y: 70 }},
    cyclops01: { texture: 'cyclops01', speed: 100, health: 1000, damage: 55, exp: 400, scale: 0.2, size: { width: 300, height: 400 },offset: { x: 100, y: 70 }},
    cyclops02: { texture: 'cyclops02', speed: 110, health: 1700, damage: 55, exp: 700, scale: 0.2, size: { width: 300, height: 400 },offset: { x: 100, y: 70 }},
    cyclops03: { texture: 'cyclops03', speed: 70, health: 2400, damage: 65, exp: 40, scale: 0.2, size: { width: 300, height: 400 },offset: { x: 100, y: 70 }},
    dragon01: { texture: 'dragon01', speed: 100, health: 900, damage: 80, exp: 500, scale: 0.4, size: { width: 300, height: 400 },offset: { x: 100, y: 70 }},
    dragon02: { texture: 'dragon02', speed: 110, health: 1800, damage: 90, exp: 2000, scale: 0.4, size: { width: 300, height: 400 },offset: { x: 100, y: 70 }},
    dragon03: { texture: 'dragon03', speed: 120, health: 2400, damage: 100, exp: 3500, scale: 0.4, size: { width: 300, height: 400 },offset: { x: 100, y: 70 }},
    phoenix01: { texture: 'phoenix01', speed: 150, health: 800, damage: 75, exp: 500, scale: 0.2, size: { width: 300, height: 400 },offset: { x: 100, y: 70 }},
    phoenix02: { texture: 'phoenix02', speed: 160, health: 1200, damage: 85, exp: 800, scale: 0.2, size: { width: 300, height: 400 },offset: { x: 100, y: 70 }},
    phoenix03: { texture: 'phoenix03', speed: 170, health: 1900, damage: 95, exp: 2500, scale: 0.2, size: { width: 300, height: 400 },offset: { x: 100, y: 70 }},
    cerberus01: { texture: 'cerberus01', speed: 150, health: 800, damage: 70, exp: 190, scale: 0.2, size: { width: 300, height: 400 },offset: { x: 100, y: 70 }},
    cerberus02: { texture: 'cerberus02', speed: 150, health: 1450, damage: 80, exp: 40, scale: 0.2, size: { width: 300, height: 400 },offset: { x: 100, y: 70 }},
    cerberus03: { texture: 'cerberus03', speed: 150, health: 2500, damage: 60, exp: 3000, scale: 0.2, size: { width: 300, height: 400 },offset: { x: 100, y: 70 }},
    gargoyle01: { texture: 'gargoyle01', speed: 120, health: 600, damage: 25, exp: 40, scale: 0.2, size: { width: 300, height: 350 },offset: { x: 100, y: 60 }},
    gargoyle02: { texture: 'gargoyle02', speed: 150, health: 800, damage: 28, exp: 80, scale: 0.2, size: { width: 300, height: 350 },offset: { x: 100, y: 60 }},
    gargoyle03: { texture: 'gargoyle03', speed: 135, health: 1200, damage: 80, exp: 900, scale: 0.2, size: { width: 300, height: 350 },offset: { x: 100, y: 60 }},
    gargoyle11: { texture: 'gargoyle11', speed: 120, health: 1400, damage: 60, exp: 700, scale: 0.2, size: { width: 300, height: 350 },offset: { x: 100, y: 60 }},
    gargoyle12: { texture: 'gargoyle12', speed: 125, health: 1795, damage: 60, exp: 1500, scale: 0.2, size: { width: 300, height: 350 },offset: { x: 100, y: 60 }},
    gargoyle13: { texture: 'gargoyle13', speed: 135, health: 2200, damage: 90, exp: 3000, scale: 0.2, size: { width: 300, height: 350 },offset: { x: 100, y: 60 }},
    spider01: { texture: 'spider01', speed: 130, health: 40, damage: 15, exp: 10, scale: 0.2, size: { width: 350, height: 250 },offset: { x: 70, y: 170 }},
    spider02: { texture: 'spider02', speed: 140, health: 80, damage: 18, exp: 20, scale: 0.2, size: { width: 350, height: 250 },offset: { x: 70, y: 170 }},
    spider03: { texture: 'spider03', speed: 150, health: 120, damage: 20, exp: 30, scale: 0.2, size: { width: 350, height: 250 },offset: { x: 70, y: 170 }},
    scorpion01: { texture: 'scorpion01', speed: 80, health: 60, damage: 22, exp: 10, scale: 0.2, size: { width: 350, height: 250 },offset: { x: 70, y: 170 }},
    scorpion02: { texture: 'scorpion02', speed: 85, health: 65, damage: 25, exp: 20, scale: 0.2, size: { width: 350, height: 250 },offset: { x: 70, y: 170 }},
    scorpion03: { texture: 'scorpion03', speed: 90, health: 200, damage: 27, exp: 30, scale: 0.2, size: { width: 350, height: 250 },offset: { x: 70, y: 170 }},
    treasureChest01: { texture: 'treasureChest01', speed: 5, health: 200, damage: 0, exp: 0, scale: 0.2, size: { width: 350, height: 250 },offset: { x: 70, y: 170 }},
    treasureChest02: { texture: 'treasureChest02', speed: 5, health: 250, damage: 0, exp: 0, scale: 0.2, size: { width: 350, height: 250 },offset: { x: 70, y: 170 }},
    treasureChest03: { texture: 'treasureChest03', speed: 5, health: 300, damage: 0, exp: 0, scale: 0.2, size: { width: 350, height: 250 },offset: { x: 70, y: 170 }},
    golem01: { texture: 'golem01', speed: 90, health: 400, damage: 400, exp: 250, scale: 0.2, size: { width: 370, height: 300 },offset: { x: 60, y: 150 }},
    golem02: { texture: 'golem02', speed: 100, health: 700, damage: 700, exp: 800, scale: 0.2, size: { width: 370, height: 300 },offset: { x: 60, y: 150 }},
    golem03: { texture: 'golem03', speed: 55, health: 250, damage: 1500, exp: 80, scale: 0.2, size: { width: 370, height: 300 },offset: { x: 60, y: 150 }},
    chicken01: { texture: 'chicken01', speed: 150, health: 30, damage: 5, exp: 10, scale: 0.2, size: { width: 350, height: 300 },offset: { x: 60, y: 150 }},
    chicken02: { texture: 'chicken02', speed: 200, health: 100, damage: 10, exp: 20, scale: 0.2, size: { width: 350, height: 300 },offset: { x: 60, y: 150 }},
    chicken03: { texture: 'chicken03', speed: 125, health: 400, damage: 15, exp: 25, scale: 0.2, size: { width: 350, height: 300 },offset: { x: 60, y: 150 }},
    dragon11: { texture: 'dragon11', speed: 100, health: 700, damage: 50, exp: 80, scale: 0.4, size: { width: 550, height: 550 },offset: { x: 30, y: 50 }},
    dragon12: { texture: 'dragon12', speed: 90, health: 2000, damage: 55, exp: 85, scale: 0.4, size: { width: 550, height: 550 },offset: { x: 30, y: 50 }},
    dragon13: { texture: 'dragon13', speed: 110, health: 2200, damage: 60, exp: 90, scale: 0.4, size: { width: 550, height: 550 },offset: { x: 30, y: 50 }},
    dragon14: { texture: 'dragon14', speed: 120, health: 3000, damage: 70, exp: 3500, scale: 0.4, size: { width: 550, height: 550 },offset: { x: 30, y: 50 }},
    boss01: { texture: 'boss01', speed: 120, health: 5000, damage: 100, exp: 500, scale: 0.4, size: { width: 500, height: 500 },offset: { x: 400, y: 50 }},
    boss02: { texture: 'boss02', speed: 125, health: 10000, damage: 120, exp: 2500, scale: 0.4, size: { width: 500, height: 500 },offset: { x: 400, y: 50 }},
    boss03: { texture: 'boss03', speed: 175, health: 20000, damage: 140, exp: 5000, scale: 0.4, size: { width: 500, height: 500 },offset: { x: 400, y: 50 }},
    boss04: { texture: 'boss04', speed: 200, health: 40000, damage: 150, exp: 10000, scale: 0.4, size: { width: 500, height: 500 },offset: { x: 400, y: 50 }},
    
};
export default monsterData;
