import Phaser from 'phaser';

export default class Player extends Phaser.Physics.Arcade.Sprite {
    constructor(scene, x, y, texture) {
        super(scene, x, y, texture);
        scene.add.existing(this); // Add to the scene
        scene.physics.add.existing(this); // Add physics

        this.setCollideWorldBounds(true); // Prevent from going out of bounds

        // Player stats
        this.health = 100; // Initial health
        this.maxHealth = 100; // Max health
        this.attack = 10; // Initial attack power
        this.speed = 200; // Initial speed
        this.level = 1; // Player level
        this.exp = 0; // Experience points

        // Add a health bar
        this.createHealthBar(scene);

        // Create animations in the Player class
        this.createAnimations(scene);

        // Reference to the scene for adding text
        this.scene = scene;
        // Touch control variables
        this.touchPointer = null;
        this.touchDirection = new Phaser.Math.Vector2(0, 0);
        
    }

    createAnimations(scene) {
        scene.anims.create({
            key: 'walk-down',
            frames: scene.anims.generateFrameNumbers('hero', { start: 0, end: 2 }),
            frameRate: 10,
            repeat: -1,
        });
        scene.anims.create({
            key: 'walk-left',
            frames: scene.anims.generateFrameNumbers('hero', { start: 3, end: 5 }),
            frameRate: 10,
            repeat: -1,
        });
        scene.anims.create({
            key: 'walk-right',
            frames: scene.anims.generateFrameNumbers('hero', { start: 6, end: 8 }),
            frameRate: 10,
            repeat: -1,
        });
        scene.anims.create({
            key: 'walk-up',
            frames: scene.anims.generateFrameNumbers('hero', { start: 9, end: 11 }),
            frameRate: 10,
            repeat: -1,
        });
    }

    createHealthBar(scene) {
        this.healthBar = scene.add.graphics();
        this.updateHealthBar();
    }

    updateHealthBar() {
        const barWidth = 40;
        const barHeight = 5;
        const x = this.x - barWidth / 2;
        const y = this.y + 20; // Position the bar under the player

        this.healthBar.clear();
        // Draw background bar (red)
        this.healthBar.fillStyle(0xff0000);
        this.healthBar.fillRect(x, y, barWidth, barHeight);
        // Draw foreground bar (green) based on current health
        this.healthBar.fillStyle(0x00ff00);
        const healthWidth = (this.health / this.maxHealth) * barWidth;
        this.healthBar.fillRect(x, y, healthWidth, barHeight);
    }

    showDamage(amount) {
        const damageText = this.scene.add.text(this.x, this.y - 20, `-${amount}`, {
            fontSize: '16px',
            color: '#ff0000',
            fontStyle: 'bold',
        });
        damageText.setOrigin(0.5);

        // Animate the text to float up and fade out
        this.scene.tweens.add({
            targets: damageText,
            y: this.y - 40, // Move upwards
            alpha: 0, // Fade out
            duration: 1000, // Duration of the animation in milliseconds
            onComplete: () => damageText.destroy(), // Destroy the text after animation
        });
    }

    takeDamage(amount) {
        this.health -= amount;
        if (this.health <= 0) {
            this.health = 0;
            this.die();
        }
        this.updateHealthBar();
        this.showDamage(amount); // Display the damage
    }

    heal(amount) {
        this.health += amount;
        if (this.health > this.maxHealth) {
            this.health = this.maxHealth;
        }
        this.updateHealthBar();
    }

    die() {
        // Handle player death (e.g., game over logic)
        this.setActive(false);
        this.setVisible(false);
        this.scene.scene.pause(); // Pause the game
        this.scene.game.reactSetScore(this.totalExp)
        // Notify React that the question modal should open
        if (this.scene.game.openQuestion) {
            this.scene.game.openQuestion(); // Call the function passed from React to open the modal
            this.scene.started=true;
        }

        this.respawn(this.scene.monsters);

    }

    move(cursors, wasd) {
        this.setVelocity(0);  // Reset velocity at the start of each frame
        
        let movingUp = false;
        let movingDown = false;
        let movingLeft = false;
        let movingRight = false;
        let isMovingVertically = false;
        let isMovingHorizontally = false;
    
        // Keyboard movement (for WASD or arrow keys)
        if (cursors.left.isDown || wasd.left.isDown) {
            this.setVelocityX(-this.speed);
            this.anims.play('walk-left', true);
            movingLeft = true;
            isMovingHorizontally = true;
        } else if (cursors.right.isDown || wasd.right.isDown) {
            this.setVelocityX(this.speed);
            this.anims.play('walk-right', true);
            movingRight = true;
            isMovingHorizontally = true;
        }
    
        if (cursors.up.isDown || wasd.up.isDown) {
            this.setVelocityY(-this.speed);
            if (!movingLeft && !movingRight) {
                this.anims.play('walk-up', true);
            }
            movingUp = true;
            isMovingVertically = true;
        } else if (cursors.down.isDown || wasd.down.isDown) {
            this.setVelocityY(this.speed);
            if (!movingLeft && !movingRight) {
                this.anims.play('walk-down', true);
            }
            movingDown = true;
            isMovingVertically = true;
        }
    
        // Touch screen movement (in world coordinates)
        if (this.touchPointer && this.touchPointer.isDown) {
            // Convert touch position to world coordinates
            const touchPos = new Phaser.Math.Vector2(this.scene.input.activePointer.worldX, this.scene.input.activePointer.worldY);
            
            let velocityX = 0;
            let velocityY = 0;
    
            // Define deadzone range (i.e., threshold to prevent unintended direction change)
            const deadzone = 10;  // Adjust this value based on how sensitive you want the movement to be
            
            // Check for North-South movement with Deadzone for East-West boundaries
            if (Math.abs(touchPos.x - this.x) > deadzone) {
                // Only change horizontal direction if outside the deadzone
                if (touchPos.x < this.x) {
                    // Move Left
                    velocityX = -this.speed;
                    movingLeft = true;
                    isMovingHorizontally = true;
                } else if (touchPos.x > this.x) {
                    // Move Right
                    velocityX = this.speed;
                    movingRight = true;
                    isMovingHorizontally = true;
                }
            }
    
            // Check for East-West movement with Deadzone for North-South boundaries
            if (Math.abs(touchPos.y - this.y) > deadzone) {
                // Only change vertical direction if outside the deadzone
                if (touchPos.y < this.y) {
                    // Move Up
                    velocityY = -this.speed;
                    movingUp = true;
                    isMovingVertically = true;
                } else if (touchPos.y > this.y) {
                    // Move Down
                    velocityY = this.speed;
                    movingDown = true;
                    isMovingVertically = true;
                }
            }
    
            // Set velocity based on the direction
            this.setVelocity(velocityX, velocityY);
    
            // Determine animation based on the dominant movement direction
            if (isMovingHorizontally && !isMovingVertically) {
                // Horizontal movement (left or right)
                if (movingLeft) {
                    this.anims.play('walk-left', true);
                } else if (movingRight) {
                    this.anims.play('walk-right', true);
                }
            } else if (isMovingVertically && !isMovingHorizontally) {
                // Vertical movement (up or down)
                if (movingUp) {
                    this.anims.play('walk-up', true);
                } else if (movingDown) {
                    this.anims.play('walk-down', true);
                }
            } else {
                // Diagonal movement
                if (movingUp && movingRight) {
                    this.anims.play('walk-right', true); // Priority to right movement animation
                } else if (movingUp && movingLeft) {
                    this.anims.play('walk-left', true);  // Priority to left movement animation
                } else if (movingDown && movingRight) {
                    this.anims.play('walk-right', true); // Priority to right movement animation
                } else if (movingDown && movingLeft) {
                    this.anims.play('walk-left', true);  // Priority to left movement animation
                }
            }
        }
    
        // Stop animation if no movement
        if (!movingLeft && !movingRight && !movingDown && !movingUp && !(this.touchPointer && this.touchPointer.isDown)) {
            this.setVelocity(0);
            this.anims.stop();
        }
    
        // Update health bar position
        this.updateHealthBar();
    }
                
    enableTouchInput() {
        this.scene.input.on('pointerdown', (pointer) => {
            this.touchPointer = pointer;
        });

        this.scene.input.on('pointerup', () => {
            this.touchPointer = null;
            this.setVelocity(0, 0);
            this.anims.stop();
        });
    }

    collectGem(player, gem) {
        if (!gem.canBeCollected) return;
        this.collectGemSound.play();  // Play the sound

        let expGained = 0;

        switch (gem.texture.key) {
            case 'gem01':
                expGained = 10;
                break;
            case 'gem02':
                expGained = 50;
                break;
            case 'gem03':
                expGained = 100;
                break;
            default:
                expGained = 0;
        }

        this.player.exp += expGained;
        this.player.totalExp += expGained;

        // Update the EXP bar
        this.updateExpBar();

        // Destroy the gem
        gem.destroy();
    }
    respawn(monstersGroup) {
        // Restore player health
        this.health = this.maxHealth;
        this.updateHealthBar();
    
        // Apply knockback to all monsters
        monstersGroup.children.iterate((monster) => {
            monster.respawnKnockBack(this)
        });
        this.setActive(true);
        this.setVisible(true);

        // Additional logic if needed (e.g., temporary invincibility)
    }
        
}
