import { useEffect, useRef, useState} from "react";
import { Input, Container, Row, Col, Card, Label, FormGroup, FormFeedback,
    Modal,  ModalBody, Button} from 'reactstrap';
import './style.css';
import Dropzone from 'react-dropzone'
import axios from 'axios';
import API from "../../utils/API";
import {useParams} from 'react-router-dom';
import TreasureHunt from "../../games/treasureHunt/treasureHunt";
import cat_zombie from '../../games/treasureHunt/assets/images/characters/cat_zombie.PNG'
import devil from '../../games/treasureHunt/assets/images/characters/devil.PNG'
import mummy from '../../games/treasureHunt/assets/images/characters/mummy.PNG'
import pirate from '../../games/treasureHunt/assets/images/characters/pirate.PNG'
import reaper from '../../games/treasureHunt/assets/images/characters/reaper.png'
import skeleton from '../../games/treasureHunt/assets/images/characters/skeleton.PNG'
import werewolf from '../../games/treasureHunt/assets/images/characters/werewolf.PNG'
import witch from '../../games/treasureHunt/assets/images/characters/witch.PNG'
import zombie from '../../games/treasureHunt/assets/images/characters/zombie.PNG'

// import { StoreContext } from "./context/store/storeContext";
const shuffleArray = (array) => {
    let newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
};
  
const TreasureHuntSetUp = () => {

    const {id} = useParams();
    const [questionSet, setQuestionSet] = useState({});
    const [teamCount, setTeamCount] = useState(0);
    const [gameStarted, setGameStarted] = useState(false);
    const [teams,setTeams] = useState({});
    const [teamOptions] = useState([
        { name: "Zombie Cat", image: cat_zombie, points:0 },
        { name: "Imp", image: devil, points:0 },
        { name: "Mummy", image: mummy, points:0 },
        { name: "Pirate", image: pirate, points:0 },
        { name: "Reaper", image: reaper, points:0 },
        { name: "Skeleton", image: skeleton, points:0 },
        { name: "Werewolf", image: werewolf, points:0 },
        { name: "Witch", image: witch, points:0 },
        { name: "Zombie", image: zombie, points:0 },

    ]);
    const [teamSetUp, setTeamSetUp] = useState(false);
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [currentTeamSelect, setCurrentTeamSelect] = useState(0);
    const [boardSize, setBoardSize] = useState(16);
    const [error, setError] = useState({});

    useEffect(()=>{
        // setTeams({0:'',1:'',2:'',3:''});
    API.getQuestionSet(id).then(res=>{
        setQuestionSet(res.data);
    }).catch(err=>{
        console.log(err)
    })
    },[])
   const onTeamCountChange = e =>{
    setTeamCount(e.target.value);
   }
   const onBoardSizeChange = e =>{
    setBoardSize(Number(e.target.value));
   }

   const onGameStart =e=>{
    let errorTemp = {...error};
    if (Object.keys(teams).length < teamCount) {
        errorTemp.mascotSelect = 'Please select your team mascots.'
        setError(errorTemp);
        return;
    } else {
        errorTemp.mascotSelect = ''
        setError(errorTemp);
    }
        setGameStarted(true);
   }

   const assignTeamsClick = ()=>{
    let errorTemp = {...error};
    if (teamCount < 2 || teamCount > 6) {
        errorTemp.teamCount = 'Must be between 2 and 6 teams'
        setError(errorTemp);
        return;
    } else {
        errorTemp.teamCount = ''
        setError(errorTemp);
    }
        setTeamSetUp(true);
   }
   const handleTeamSelection = (team)=>{
    if(currentTeamSelect==null){
        return;
    }
        let filteredTeams = selectedTeams.filter(selected => selected !== teams[currentTeamSelect]);
        let teamsTemp = {...teams};
        teamsTemp[currentTeamSelect] = team;
        setTeams(teamsTemp);
        let selectedTeamsTemp = filteredTeams
        selectedTeamsTemp.push(team)
        setSelectedTeams(selectedTeamsTemp);
        for(let i = 0; i<teamCount; i++){
            if(!teamsTemp[i]){
                setCurrentTeamSelect(i)
                break;
            } else{
                setCurrentTeamSelect(null)
            }
        }

   };
   const changeThisTeamClick = (key)=>{
        setCurrentTeamSelect(key);
   }
  return (

    <div>
        {gameStarted?
            <TreasureHunt
                questionSet={questionSet}
                teamCount={teamCount}
                teams={teams}
                boardSize={boardSize}
            ></TreasureHunt>   
          :
            <div className="background-blue-95vh vertical-center ">
                <Container className="h-100" fluid>
                    <Row className="h-100">
                        <Col xs="3">
                        </Col>
                        <Col md="12" lg="6">
                            {teamSetUp?
                            <Card className="text-center">
                                <Container className="" fluid>
                                <h3 className="mb-3">Select Your Team Mascots</h3>
                                <Row className="justify-content-center align-items-center ">
                                         <p className="text-danger">{error.mascotSelect}</p>
                                </Row>

                                <Row className="justify-content-center align-items-center m-3">

                                {[...Array(Number(teamCount)).keys()].map((key) => {
                                        if(teams[key]){
                                            return (<Col xs="auto" key={key} onClick={() => changeThisTeamClick(key)} className={`team-option assign-team-image-container ${currentTeamSelect === key ? 'team-change-selected' : ''}`}>
                                                <p>Team {teams[key].name}</p>
                                                <img className='assign-team-image' src={teams[key].image} alt={teams[key].name} />
                                            </Col>)

                                        } else {
                                            return (
                                                <Col xs="auto" key={key} onClick={() => changeThisTeamClick(key)} className={`team-option unassigned-icon-container ${currentTeamSelect === key ? 'team-change-selected' : ''}`}>
                                                <p>Team {key + 1}</p>
                                                <i class=" unassigned-icon fa-solid fa-question"></i>
                                            </Col>
                                            )
                                        }
                                        
                                })}
                                </Row>
                                <h3 className="mb-3">Team Mascots</h3>

                                    <Row className="justify-content-center align-items-center text-center m-3 ">
                                        {teamOptions.map((team) => (
                                            <Col xs="auto"  key={team.name} onClick={() => handleTeamSelection(team)} className={` team-option assign-team-image-container  ${selectedTeams.includes(team) ? 'selected-teams' : ''}`}>
                                                <img className='assign-team-image' src={team.image} alt={team.name} />
                                                <p>{team.name}</p>
                                            </Col>
                                        ))}
                                    </Row>
                                </Container>
                                <button onClick={e=>onGameStart()} className="btn btn-rounded nav-btn-light-blue navbar-btn">Start</button>
                            </Card>

                            :
                            <Card className="text-center">
                                <h3 className="mb-3">{questionSet.title}</h3>
                                {questionSet.image && <img className='setup-image mb-3' src={questionSet.image}></img>}
                                {questionSet.description && <p className="mb-3" >{questionSet.description}</p>}
                                {/* team select counter */}
                                <Container className="">
                                     <Row className="justify-content-center align-items-center ">
                                         <p className="text-danger">{error.teamCount}</p>

                                    </Row>
                                    <Row className="justify-content-center align-items-center ">
                                            <Col xs="auto" className="mb-3">
                                                <Label for="teamCount" className="m-0 align-middle">Enter # of Teams (2-6)</Label>
                                            </Col>
                                            <Col xs="auto" className="mb-3">
                                                <Input type="number" id="teamCount" min="0" max="10" onChange={e=>{onTeamCountChange(e)}}/>
                                            </Col>
                                            
                                    </Row>
                                    <Row className="justify-content-center align-items-center ">
                                    <FormGroup row className="mb-3">
                                        <Col xs="auto">
                                            <Label for="boardSize" className=" m-0 align-middle">Select Board Size</Label>
                                        </Col>
                                        <Col xs="auto">
                                            <Input type="select" id="boardSize" defaultValue="16" onChange={e => onBoardSizeChange(e)} className="align-middle">
                                                <option value="9">9</option>
                                                <option value="16">16</option>
                                                <option value="36">36</option>
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    </Row>

                                </Container>

                                <button onClick={e=>assignTeamsClick()} className="btn btn-rounded nav-btn-light-blue navbar-btn">Start</button>
                            </Card>

                            }
                        </Col>
                    </Row>
                </Container>
            </div>

        }
    </div>

  );
};

export default TreasureHuntSetUp; 