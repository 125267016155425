import Phaser from "phaser";

export default class SpiritBall {
    constructor(scene, player, monsters, config) {
        this.scene = scene;           // Phaser scene
        this.player = player;         // Player reference
        this.monsters = monsters;     // Monster group
        this.config = { ...config };  // Copy of the configuration
        this.type = 'spiritBall';     // Identifier for this skill
        this.canShoot = true;         // Track cooldown state
        this.projectiles = this.scene.physics.add.group(); // Group for SpiritBall projectiles

        // Add collision handling for projectiles and monsters
        this.scene.physics.add.overlap(this.projectiles, this.monsters, this.handleHit, null, this);
    }

    update() {
        if (!this.canShoot) return;

        const target = this.findClosestMonster();
        if (target) {
            this.shoot(target); // Shoot the spirit ball at the closest monster
            this.setCooldown();
        }

        // Update each projectile in the projectiles group to check if it should be destroyed
        this.projectiles.getChildren().forEach(spiritBall => {
            this.updateProjectileRange(spiritBall);
        });
    }

    shoot(target) {
        // Create a new SpiritBall sprite with physics enabled
        const spiritBall = this.scene.physics.add.sprite(this.player.x, this.player.y, 'spiritBall01');
        
        // Adjust scale based on the current size (upgradable)
        spiritBall.setScale(this.config.size);
    
        // Add the SpiritBall to the projectiles group
        this.projectiles.add(spiritBall);
    
        // Play animation for the spirit ball
        spiritBall.anims.play('spiritballAnim', true);
    
        // Initialize hit count and distance tracking for the SpiritBall
        spiritBall.hitCount = 0;
        spiritBall.hitMonsters = new Set(); // Use a Set to track hit monsters
        spiritBall.startX = this.player.x; // Starting x position
        spiritBall.startY = this.player.y; // Starting y position
        spiritBall.distanceTraveled = 0;
    
        // Set the physics body to be circular with a radius based on the size stat
        if (spiritBall.body) {
            spiritBall.body.setCircle(14); // Collision box size for SpiritBall
            spiritBall.body.setOffset(2, 2); // Offset to move the body slightly down and to the right
        }
        
        // Set the origin of the sprite to the center (0.5, 0.5)
        spiritBall.setOrigin(0.5, 0.5);
    
        // Rotate the SpiritBall to face the target
        const angle = Phaser.Math.Angle.Between(this.player.x, this.player.y, target.x, target.y);
        spiritBall.rotation = angle;
    
        // Move the SpiritBall toward the target
        this.scene.physics.moveTo(spiritBall, target.x, target.y, this.config.speed); // Adjust speed for SpiritBall
    }

    setCooldown() {
        this.canShoot = false;
        this.scene.time.delayedCall(this.config.attackSpeed, () => {
            this.canShoot = true;
        });
    }

    upgradeStat(stat, value) {
        if (this.config[stat] !== undefined) {
            this.config[stat] += value;
        }
    }

    findClosestMonster() {
        // Find the closest monster within range
        const monstersInRange = this.monsters.getChildren()
            .map(monster => ({
                monster,
                distance: Phaser.Math.Distance.Between(this.player.x, this.player.y, monster.x, monster.y),
            }))
            .filter(({ distance }) => distance <= this.config.range)
            .sort((a, b) => a.distance - b.distance);

        // Return the closest monster
        return monstersInRange.length > 0 ? monstersInRange[0].monster : null;
    }

    updateProjectileRange(spiritBall) {
        // Calculate distance traveled from the start point
        const dx = spiritBall.x - spiritBall.startX;
        const dy = spiritBall.y - spiritBall.startY;
        spiritBall.distanceTraveled = Math.sqrt(dx * dx + dy * dy);

        // Destroy the SpiritBall if it exceeds its range
        if (spiritBall.distanceTraveled >= this.config.range) {
            spiritBall.destroy();
        }
    }

    handleHit(projectile, monster) {
        // Ensure the SpiritBall has not already hit this monster
        if (projectile.hitMonsters.has(monster)) {
            return;
        }

        // Mark the monster as hit by this SpiritBall
        projectile.hitMonsters.add(monster);

        // Deal damage to the monster
        const damage = this.config.damage;
        monster.takeDamage(damage);
    }
}
