// spawnData.js

export const spawnData = {
    // Levels 1-5
    '1-5': [
        { type: 'bat01', movement: 'transition' },
        { type: 'slime01', movement: 'following' },
        { type: 'chicken01', movement: 'following' },
        { type: 'spider01', movement: 'following' },
        { type: 'snake01', movement: 'following' },

    ],
    // Levels 6-10
    '6-10': [
        { type: 'ogre01', movement: 'following' },
        { type: 'bat02', movement: 'transition' },
        { type: 'shroom01', movement: 'following' },
        { type: 'treant01', movement: 'following' },

    ],
    '11-15': [
        { type: 'treant02', movement: 'following' },
        { type: 'ogre02', movement: 'following' },
        { type: 'hatchling01', movement: 'following' },
        { type: 'bat02', movement: 'transition' },
        { type: 'knight01', movement: 'following' },

    ],
    '16-20': [
        { type: 'slime02', movement: 'following' },
        { type: 'bat03', movement: 'transition' },
        { type: 'knight02', movement: 'following' },
        { type: 'zombie01', movement: 'following' },
        { type: 'gargoyle01', movement: 'following' },
        { type: 'chicken02', movement: 'following' }
    ],
    '21-25': [
        { type: 'slime03', movement: 'following' },
        { type: 'gargoyle02', movement: 'following' },
        { type: 'zombie02', movement: 'following' },
        { type: 'spider03', movement: 'following' },
    ],
    '26-30': [
        { type: 'ogre03', movement: 'following' },
        { type: 'hatchling02', movement: 'following' },
        { type: 'scorpion03', movement: 'following' },
    ],
    '31-35': [
        { type: 'cerberus01', movement: 'following' },
        { type: 'golem01', movement: 'following' },
        { type: 'zombie03', movement: 'following' },
        { type: 'treant03', movement: 'following' },
    ],
    '36-40': [
        { type: 'ogre11', movement: 'following' },
        { type: 'cyclops01', movement: 'following' },
        { type: 'phoenix01', movement: 'transition' },
        { type: 'treant03', movement: 'following' },
    ],
    '41-45': [
        { type: 'ogre11', movement: 'following' },
        { type: 'dragon01', movement: 'following' },
        { type: 'phoenix01', movement: 'transition' },
        { type: 'gargoyle03', movement: 'following' },
    ],
    '46-50': [
        { type: 'ogre12', movement: 'following' },
        { type: 'cyclops02', movement: 'following' },
        { type: 'phoenix02', movement: 'transition' },
        { type: 'gargoyle11', movement: 'following' },
    ],
    '51-55': [
        { type: 'golem02', movement: 'following' },
        { type: 'cyclops02', movement: 'following' },
        { type: 'phoenix02', movement: 'transition' },
        { type: 'gargoyle12', movement: 'following' },
    ],
    '56-60': [
        { type: 'ogre13', movement: 'following' },
        { type: 'dragon02', movement: 'following' },
        { type: 'phoenix02', movement: 'transition' },
        { type: 'gargoyle12', movement: 'following' },
    ],
    '61-65': [
        { type: 'ogre13', movement: 'following' },
        { type: 'dragon02', movement: 'following' },
        { type: 'phoenix03', movement: 'transition' },
        { type: 'gargoyle12', movement: 'following' },
    ],
    '66-70': [
        { type: 'dragon14', movement: 'following' },
        { type: 'phoenix03', movement: 'transition' },
        { type: 'gargoyle13', movement: 'following' },
        { type: 'cerberus03', movement: 'following' },
        { type: 'treasureChest03', movement: 'following' },
        { type: 'dragon03', movement: 'following' },

    ],
    '71+': [
        { type: 'dragon14', movement: 'following' },
        { type: 'phoenix03', movement: 'transition' },
        { type: 'gargoyle13', movement: 'following' },
        { type: 'cerberus03', movement: 'following' },
        { type: 'treasureChest03', movement: 'following' },
        { type: 'dragon03', movement: 'following' },
        { type: 'boss01', movement: 'following' },
        { type: 'boss02', movement: 'following' },
        { type: 'boss03', movement: 'following' },
        { type: 'boss04', movement: 'following' }
    ]

};
