import Phaser from 'phaser';

export default class RingOfFire {
    constructor(scene, player, monsters, config) {
        this.scene = scene;           // Reference to the Phaser scene
        this.player = player;         // Player reference
        this.monsters = monsters;     // Monster group
        this.config = { ...config };  // Skill configuration
        this.type = 'ringOfFire';     // Identifier for this skill

        // Track last damage times for each monster
        this.lastDamageTimes = new Map();

        // Create the Ring of Fire sprite
        this.ringOfFire = this.scene.add.sprite(this.player.x, this.player.y, 'ringOfFire');
        this.ringOfFire.setOrigin(0.5, 0.5);
        this.ringOfFire.setScale(this.config.range); // Adjust visual size; physics will be based on `range`

        // Create and play the animation for the ring
        if (!this.scene.anims.exists('ringOfFire')) {
            this.scene.anims.create({
                key: 'ringOfFire',
                frames: this.scene.anims.generateFrameNumbers('ringOfFire', { start: 0, end: 27 }),
                frameRate: 20, // Speed of the animation
                repeat: -1 // Loop indefinitely
            });
        }
        this.ringOfFire.play('ringOfFire');

        // Set physics for overlap detection
        this.scene.physics.add.existing(this.ringOfFire);
        this.ringOfFire.body.setCircle(50); // Adjust range based on config
        this.ringOfFire.body.setImmovable(true);
        // Update the Ring of Fire's position every frame
        this.scene.events.on('update', this.updateRing, this);

        // Add overlap with monsters
        this.scene.physics.add.overlap(this.ringOfFire, this.monsters, this.tryHitMonster, null, this);
    }

    // Update the Ring of Fire's position to stay centered around the player
    update(){}
    updateRing() {
        this.ringOfFire.setPosition(this.player.x, this.player.y);
    }

    // Attempt to damage a monster if conditions are met
    tryHitMonster(ring, monster) {
        const currentTime = this.scene.time.now;

        // Get the current animation frame index
        const currentAnimation = this.ringOfFire.anims.currentAnim;
        const currentFrame = this.ringOfFire.anims.currentFrame.index;

        if (!currentAnimation) return;

        // Check if the frame is in the last 25% of the animation
        const totalFrames = currentAnimation.frames.length;
        const lastQuarterStartFrame = Math.floor(totalFrames * 0.5);

        if (currentFrame >= lastQuarterStartFrame) {
            // Check the monster's last damage time
            const lastDamageTime = this.lastDamageTimes.get(monster) || 0;

            if (currentTime - lastDamageTime >= this.config.attackSpeed) { // Delay between damage instances
                this.lastDamageTimes.set(monster, currentTime); // Update the monster's last damage time
                monster.takeDamage(this.config.damage);
            }
        }
    }

    // Upgrade a specific stat of the skill
    upgradeStat(stat, value) {
        if (this.config[stat] !== undefined) {
            this.config[stat] += value;

            // If range changes, update the physics body size
            if (stat === 'range') {
                this.ringOfFire.setScale(this.config.range); // Adjust visual size; physics will be based on `range`
            }

        }
    }

    // Destroy the Ring of Fire
    destroy() {
        this.ringOfFire.destroy();
        this.lastDamageTimes.clear();
    }
}

