import Phaser from "phaser";

export default class Tornado {
    constructor(scene, player, monsters, config) {
        this.scene = scene;           // Phaser scene
        this.player = player;         // Player reference 
        this.monsters = monsters;     // Monster group
        this.config = { ...config };  // Copy of the configuration
        this.type = 'tornado';        // Identifier for this skill
        this.canShoot = true;         // Track cooldown state
        this.projectiles = this.scene.physics.add.group(); // Group for tornado projectiles

        // Add collision handling for projectiles and monsters
        this.scene.physics.add.overlap(this.projectiles, this.monsters, this.handleHit, null, this);
    }

    update() {
        if (!this.canShoot) return;

        const targets = this.findClosestMonsters(this.config.numTornadoes);  // Find closest monsters
        if (targets.length > 0) {
            this.shoot(targets);  // Shoot tornado at targets
            this.setCooldown();
        }

        // Update projectiles' position and destroy them if they exceed range
        this.projectiles.getChildren().forEach(tornado => {
            this.updateTornadoRange(tornado);
        });
    }

    shoot(targets) {
        targets.forEach(target => {
            // Create a new tornado sprite with physics enabled
            const tornado = this.scene.physics.add.sprite(this.player.x, this.player.y, 'tornado01');
            tornado.play('tornado-spin');  // Play the tornado animation

            this.projectiles.add(tornado);  // Add to group for collision handling
            tornado.setScale(this.config.size);  // Scale the tornado based on the upgraded size

            // Initialize hit tracking for the tornado
            tornado.hitCount = 0;
            tornado.hitMonsters = new Set(); // Track monsters hit by this tornado
            tornado.startX = this.player.x; // Starting x position
            tornado.startY = this.player.y; // Starting y position
            tornado.distanceTraveled = 0;

            // Ensure the physics body exists
            if (tornado.body) {
                tornado.body.setSize(50, 50); // Set collision box size
            }

            // Rotate the tornado to face the closest target
            // const angle = Phaser.Math.Angle.Between(this.player.x, this.player.y, target.x, target.y);
            // tornado.rotation = angle;

            // Move the tornado toward the target
            this.scene.physics.moveTo(tornado, target.x, target.y, this.config.speed);  // Adjust speed as needed
        });
    }

    setCooldown() {
        this.canShoot = false;
        this.scene.time.delayedCall(this.config.attackSpeed, () => {
            this.canShoot = true;
        });
    }

    upgradeStat(stat, value) {
        if (this.config[stat] !== undefined) {
            this.config[stat] += value;
            console.log(`Upgraded ${stat} to ${this.config[stat]}`);
        }
    }

    findClosestMonsters(count) {
        // Sort monsters by distance
        const monstersInRange = this.monsters.getChildren()
            .map(monster => ({
                monster,
                distance: Phaser.Math.Distance.Between(this.player.x, this.player.y, monster.x, monster.y),
            }))
            .filter(({ distance }) => distance <= this.config.range)
            .sort((a, b) => a.distance - b.distance);

        // Return the closest `count` monsters
        return monstersInRange.slice(0, count).map(entry => entry.monster);
    }

    updateTornadoRange(tornado) {
        // Calculate distance traveled from the start point
        const dx = tornado.x - tornado.startX;
        const dy = tornado.y - tornado.startY;
        tornado.distanceTraveled = Math.sqrt(dx * dx + dy * dy);

        // Destroy the tornado if it exceeds its range
        if (tornado.distanceTraveled >= this.config.range) {
            tornado.destroy();
        }
    }
    
    handleHit(projectile, monster) {
        // Ensure the projectile (tornado) and monster have valid physics bodies
        if (!projectile.body || !monster.body) return;
    
        // Check if the monster has already been hit by this tornado
        if (projectile.hitMonsters.has(monster)) {
            return; // Skip further processing for this monster
        }
    
        // Mark the monster as hit
        projectile.hitMonsters.add(monster);
    
        // Calculate knockback direction (away from the tornado)
        const dx = monster.x - projectile.x; // Direction on x-axis
        const dy = monster.y - projectile.y; // Direction on y-axis
        const magnitude = Math.sqrt(dx * dx + dy * dy); // Calculate the distance (magnitude of the vector)
    
        if (magnitude > 0) {
            // Normalize the direction vector and apply knockback force
            const knockbackForce = 500; // Adjust the strength of the knockback
            monster.body.setVelocity((dx / magnitude) * knockbackForce, (dy / magnitude) * knockbackForce);
            monster.knockback = true; // Set knockback flag
        }
    
        // Deal damage to the monster
        const damage = this.config.damage;
        if (typeof monster.takeDamage === "function") {
            monster.takeDamage(damage); // Ensure the monster has a takeDamage method
        } else {
            console.warn("Monster object does not have a 'takeDamage' method.");
        }
    
        // Stop knockback after a short delay
        projectile.scene.time.delayedCall(200, () => {
            if (monster.body) {
                monster.knockback = false; // Reset knockback flag
                monster.body.setVelocity(0, 0); // Stop movement after knockback
            }
        });
    
        // Increment the tornado's hit count
        projectile.hitCount += 1;
    
        // Destroy the projectile if it exceeds the target hit limit
        if (projectile.hitCount >= this.config.targets) {
            projectile.destroy();
        }
    }
    }
