import Phaser from 'phaser';

class Coin extends Phaser.Physics.Arcade.Sprite {
  constructor(scene, x, y) {
    super(scene, x, y, 'atlas', 'coin_0');

    // Add the sprite to the scene
    scene.add.existing(this);
    scene.physics.add.existing(this);

    // Set up animation
    this.anims.create({
      key: 'idle',
      frames: this.anims.generateFrameNames('atlas', {
        prefix: 'coin_',
        start: 0,
        end: 9
      }),
      frameRate: 10,
      repeat: -1
    });
    this.play('idle');

    // Set up physics
    this.setCircle(24); // Set the physics shape for the coin
    // this.body.setCollideWorldBounds(true); // Ensure it doesn't go out of bounds
    // this.body.setImmovable(true); // Coin doesn't move due to physics
    // this.body.setVelocityX(-300); // Move the coin leftward
    this.body.allowGravity = false; // Disable gravity
    // Set velocity
    // this.setVelocityX(-scene.speed * 60);

    // Optional: Add a tween for scaling effect
    this.tween = scene.tweens.add({
      targets: this,
      scaleX: 1.1,
      scaleY: 1.1,
      duration: 300,
      yoyo: true,
      repeat: -1,
      ease: 'Linear'
    });
  }

  update() {
    this.x -= this.scene.speed;
    // this.body.x -= this.scene.speed;

    if (this.x < -this.width) {
      this.destroy(); // Automatically destroys the body when destroying the sprite
    }
  }
}

export default Coin;
