const skillUpgradeOptions = {
    laser: [
        {
            stat: 'attackSpeed',
            value: -50, // Reducing cooldown (faster attacks)
            max: 200, 
            description: 'Increase laser attack speed',
            iconKey: 'laser_icon'
        },
        {
            stat: 'damage',
            value: 10,
            max: 1000,
            description: 'Increase laser damage',
            iconKey: 'laser_icon'
        },
        // {
        //     stat: 'range',
        //     value: 50,
        //     max: 1000,
        //     description: 'Increase laser range',
        //     iconKey: 'laser_icon'
        // },
        {
            stat: 'numLasers',
            value: 1,
            max: 5,
            description: 'Increase the number of lasers shot',
            iconKey: 'laser_icon'
        },
        // {
        //     stat: 'targets',
        //     value: 1,
        //     max: 10,
        //     description: 'Increase the number of monsters a laser can hit',
        //     iconKey: 'laser_icon'
        // },
    ],
    ringOfFire: [
        {
            stat: 'attackSpeed',
            value: -200,
            max: 500,
            description: 'Increase ring of fire attack speed',
            iconKey: 'ringOfFire_icon'
        },
        {
            stat: 'damage',
            value: 20,
            max: 1000,
            description: 'Increase ring of fire damage',
            iconKey: 'ringOfFire_icon'
        },
        // {
        //     stat: 'range',
        //     value: 1,
        //     max: 4,
        //     description: 'Increase size of ring of fire',
        //     iconKey: 'ringOfFire_icon'
        // },
    ],
    icicle: [
        {
            stat: 'attackSpeed',
            value: -100,
            max: 2,
            description: 'Increase icicle attack speed',
            iconKey: 'icicle_icon'
        },
        {
            stat: 'damage',
            value: 5,
            max: 1000,
            description: 'Increase icicle damage',
            iconKey: 'icicle_icon'
        },
        // {
        //     stat: 'range',
        //     value: 100,
        //     max: 1000,
        //     description: 'Increase icicle range',
        //     iconKey: 'icicle_icon'
        // },
        // {
        //     stat: 'speed',
        //     value: 100,
        //     max: 1500,
        //     description: 'Increase icicle movement speed',
        //     iconKey: 'icicle_icon'
        // },
        {
            stat: 'numIcicles',
            value: 1,
            max: 6,
            description: 'Increase the number of icicles shot',
            iconKey: 'icicle_icon'
        },
    ],
    shuriken: [
        {
            stat: 'attackSpeed',
            value: -50,
            max: 300,
            description: 'increase shuriken attack speed',
            iconKey: 'shuriken_icon'
        },
        {
            stat: 'damage',
            value: 10,
            max: 1000,
            description: 'Increase shuriken damage',
            iconKey: 'shuriken_icon'
        },
        // {
        //     stat: 'range',
        //     value: 100,
        //     max: 1000,
        //     description: 'Increase shuriken range',
        //     iconKey: 'shuriken_icon'
        // },
        {
            stat: 'numShurikens',
            value: 1,
            max: 10,
            description: 'Increase the number of shurikens thrown',
            iconKey: 'shuriken_icon'
        },
    ],
    tornado: [
        {
            stat: 'attackSpeed',
            value: -100,
            max: 500,
            description: 'Increase tornado attack speed',
            iconKey: 'tornado_icon'
        },
        {
            stat: 'damage',
            value: 10,
            max: 1000,
            description: 'Increase tornado damage',
            iconKey: 'tornado_icon'
        },
        // {
        //     stat: 'range',
        //     value: 100,
        //     max: 1000,
        //     description: 'Increase tornado range',
        //     iconKey: 'tornado_icon'
        // },
        // {
        //     stat: 'speed',
        //     value: 50,
        //     max: 600,
        //     description: 'Increase tornado movement speed',
        //     iconKey: 'tornado_icon'
        // },
        // {
        //     stat: 'knockbackForce',
        //     value: 50,
        //     max: 600,
        //     description: 'Increase tornado knockback',
        //     iconKey: 'tornado_icon'
        // },
        {
            stat: 'numTornadoes',
            value: 1,
            max: 4,
            description: 'Increase the number of tornadoes summoned',
            iconKey: 'tornado_icon'
        },
    ],
    spiritBall: [
        {
            stat: 'attackSpeed',
            value: -1000,
            max: 2000,
            description: 'Reduce cooldown for spirit ball attacks',
            iconKey: 'spiritBall_icon'
        },
        {
            stat: 'damage',
            value: 30,
            max: 1000,
            description: 'Increase spirit ball damage',
            iconKey: 'spiritBall_icon'
        },
        // {
        //     stat: 'range',
        //     value: 300,
        //     max: 900,
        //     description: 'Increase spirit ball range',
        //     iconKey: 'spiritBall_icon'
        // },
        // {
        //     stat: 'size',
        //     value: 1,
        //     max:8,
        //     description: 'Increase spirit ball size',
        //     iconKey: 'spiritBall_icon'
        // },
        // {
        //     stat: 'speed',
        //     value: 200,
        //     max: 800,
        //     description: 'Increase spirit ball movement speed',
        //     iconKey: 'spiritBall_icon'
        // },
    ],
    boomerang: [
        {
            stat: 'attackSpeed',
            value: -500,
            max: 1000,
            description: 'Increase boomerang attack speed',
            iconKey: 'boomerang_icon'
        },
        {
            stat: 'damage',
            value: 15,
            max: 1000,
            description: 'Increase boomerang damage',
            iconKey: 'boomerang_icon'
        },
        // {
        //     stat: 'range',
        //     value: 150,
        //     max: 600,
        //     description: 'Increase boomerang range',
        //     iconKey: 'boomerang_icon'
        // },
        // {
        //     stat: 'size',
        //     value: 1,
        //     max: 5,
        //     description: 'Increase boomerang size',
        //     iconKey: 'boomerang_icon'
        // },
        // {
        //     stat: 'speed',
        //     value: 200,
        //     max: 800,
        //     description: 'Increase boomerang speed',
        //     iconKey: 'boomerang_icon'
        // },
    ],
};

export default skillUpgradeOptions;
