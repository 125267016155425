import React from "react";
import { Container, Row, Col, Card } from "reactstrap";
import { PieChart, Pie, Cell, Tooltip } from "recharts";

const QuizReport = ({ quizData }) => {
  // Mock Data (Remove when integrating real data)
//  quizData = {
  //   questions: [
  //     {
  //       id: 58,
  //       question: "What is 2+2?",
  //       selectedAnswer: { answer: "4", correctAnswer: "true" },
  //       multiAnswers: {
  //         answer1: { answer: "4", correctAnswer: "true" },
  //         answer2: { answer: "6", correctAnswer: "false" },
  //         answer3: { answer: "3", correctAnswer: "false" },
  //         answer4: { answer: "2", correctAnswer: "false" },
  //       },
  //       image:
  //         "https://images.unsplash.com/photo-1536146021566-627ce3c4d813?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w2NTIzNjl8MHwxfHNlYXJjaHw5fHxtb25rZXl8ZW58MHx8fHwxNzI1OTg2ODg2fDA&ixlib=rb-4.0.3&q=80&w=400",
  //     },
  //     {
  //       id: 41,
  //       question: "What is 5+5?",
  //       selectedAnswer: { answer: "10", correctAnswer: "true" },
  //       multiAnswers: {
  //         answer1: { answer: "10", correctAnswer: "true" },
  //         answer2: { answer: "9", correctAnswer: "false" },
  //         answer3: { answer: "8", correctAnswer: "false" },
  //         answer4: { answer: "7", correctAnswer: "false" },
  //       },
  //       image: "https://edu-game.s3.amazonaws.com/1721498665146637.jpg",
  //     },
  //     {
  //       id: 38,
  //       question: "What is 1+1?",
  //       selectedAnswer: { answer: "3", correctAnswer: "false" },
  //       multiAnswers: {
  //         answer1: { answer: "2", correctAnswer: "true" },
  //         answer2: { answer: "3", correctAnswer: "false" },
  //         answer3: { answer: "4", correctAnswer: "false" },
  //         answer4: { answer: "5", correctAnswer: "false" },
  //       },
  //       image:
  //         "https://cdn.pixabay.com/photo/2017/01/12/21/19/search-1975707_150.jpg",
  //     },
  //     {
  //       id: 41,
  //       question: "What is 5+5?",
  //       selectedAnswer: { answer: "9", correctAnswer: "false" },
  //       multiAnswers: {
  //         answer1: { answer: "10", correctAnswer: "true" },
  //         answer2: { answer: "9", correctAnswer: "false" },
  //         answer3: { answer: "8", correctAnswer: "false" },
  //         answer4: { answer: "7", correctAnswer: "false" },
  //       },
  //       image: "https://edu-game.s3.amazonaws.com/1721498665146637.jpg",
  //     },
  //   ],
  //   questionsAnswered: 4,
  //   totalCorrect: 2,
  // };
  

  // Group questions by ID and calculate statistics
  const groupedQuestions = quizData.questions.reduce((acc, question) => {
    if (!acc[question.id]) {
      acc[question.id] = {
        id: question.id,
        question: question.question,
        image: question.image,
        correctCount: 0,
        totalCount: 0,
      };
    }
    acc[question.id].totalCount += 1;
    if (question.selectedAnswer.correctAnswer==='true') {
      acc[question.id].correctCount += 1;
    }
    return acc;
  }, {});

  const groupedData = Object.values(groupedQuestions).map((question) => ({
    ...question,
    percentage: Math.round((question.correctCount / question.totalCount) * 100),
  }));

  // Calculate overall percentage
  const percentageCorrect = Math.round((quizData.totalCorrect / quizData.questionsAnswered) * 100);

  // Prepare data for the pie chart
  const pieData = [
    { name: "Correct", value: quizData.totalCorrect },
    { name: "Incorrect", value: quizData.questionsAnswered - quizData.totalCorrect },
  ];

  // Pie chart colors
  const pieColors = ["#1ff31e", "#fb3c3c"];

  // Determine text color for percentage based on the score
  const percentageColor = percentageCorrect >= 70 ? "#1ff31e" : percentageCorrect >= 50 ? "#ffa500" : "#fb3c3c";

  return (
    <Container className="mt-4">
      <Row>
        <Col>
          <Card className="p-4">
            <h5 className="text-center">Performance Overview</h5>
            {/* Percentage Correct */}
            <h6 className="text-center mb-4">
              Correct Answers: {quizData.totalCorrect}/{quizData.questionsAnswered} ({percentageCorrect}%)
            </h6>
            {/* Ring Pie Chart */}
            <div className="d-flex justify-content-center position-relative mb-4">
              <div
                className="position-absolute"
                style={{
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  color: percentageColor,
                }}
              >
                {percentageCorrect}%
              </div>
              <PieChart width={300} height={300}>
                <Pie
                  data={pieData}
                  cx="50%"
                  cy="50%"
                  innerRadius={70}
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                  startAngle={90}
                  endAngle={450}
                  paddingAngle={1}
                >
                  {pieData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={pieColors[index]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </div>

            <h5 className="text-center mt-4">Question Breakdown</h5>

            {groupedData.map((question, index) => (
              <Card key={question.id} className="p-3 mb-4">
                <Row>
                      {/* Left Half: Question and Image */}
                      <Col sm={6}>
                        <Row>
                          {/* Left: Question */}
                          <Col xs={6} className="text-center d-flex flex-column justify-content-center">
                            <h6>Question {index + 1}:</h6>
                            <p>{question.question}</p>
                          </Col>
                          {/* Right: Image */}
                          <Col xs={6}>
                            {question.image && (
                              <img
                                src={question.image}
                                alt={`Question ${index + 1}`}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  maxHeight: "150px",
                                  objectFit: "cover",
                                  borderRadius: "8px",
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                      </Col>

                  {/* Right Half: Performance and Bar */}
                  <Col sm={6} className="d-flex flex-column justify-content-center">
                    <h6 className="text-center mb-3">
                      Performance: {question.correctCount}/{question.totalCount} ({question.percentage}%)
                    </h6>
                    <div
                      style={{
                        position: "relative",
                        height: "30px",
                        background: "#f0f0f0",
                        borderRadius: "15px",
                        overflow: "hidden",
                      }}
                    >
                      {/* Green Bar */}
                      <div
                        style={{
                          position: "absolute",
                          height: "100%",
                          width: `${question.percentage}%`,
                          background: "#1ff31e",
                        }}
                      ></div>
                      {/* Red Bar */}
                      <div
                        style={{
                          position: "absolute",
                          height: "100%",
                          width: `${100 - question.percentage}%`,
                          background: "#fb3c3c",
                          left: `${question.percentage}%`,
                        }}
                      ></div>
                      {/* Text Labels */}
                      <div
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "0 10px",
                          color: "#fff",
                          fontWeight: "bold",
                        }}
                      >
                        <span>{question.correctCount} Correct</span>
                        <span>{question.totalCount - question.correctCount} Incorrect</span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card>
            ))}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default QuizReport;


            // {/* Detailed Question Breakdown */}
            // <div>
            //   <h6 className="text-center">Question Breakdown</h6>
            //   {quizData.questions.map((question, index) => {
            //     const isCorrect = question.selectedAnswer.correct;
            //     return (
            //       <Card
            //         key={question.id}
            //         className="p-3 mb-3"
            //         style={{
            //           border: `3px solid ${isCorrect ? "#28a745" : "#dc3545"}`, // Vibrant green or red border
            //           borderRadius: "10px",
            //           boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            //         }}
            //       >
            //         <h6 className="mb-2">Question {index + 1}:</h6>
            //         <p className="mb-2">
            //           <strong>{question.question}</strong>
            //         </p>
            //         {question.image && (
            //           <img
            //             src={question.image}
            //             alt={`Question ${index + 1}`}
            //             style={{
            //               width: "100%",
            //               maxHeight: "150px",
            //               objectFit: "cover",
            //               borderRadius: "8px",
            //             }}
            //             className="mb-3"
            //           />
            //         )}
            //         <p>
            //           <strong>Your Answer:</strong>{" "}
            //           <span style={{ color: isCorrect ? "#28a745" : "#dc3545" }}>
            //             {question.selectedAnswer.text}
            //           </span>
            //         </p>
            //         <p>
            //           <strong>Correct Answer:</strong> {question.multiAnswers.correct}
            //         </p>
            //       </Card>
            //     );
            //   })}
            // </div>
