import { useState, useContext,useCallback, useEffect, useRef} from "react";
import Phaser from 'phaser';
import BootState from './states/BootState';
import GameState from './states/GameState';
import PreloadState from './states/PreloadState';
import MainMenuState from './states/MainMenuState';
import QuizModal from '../../components/QuizModal'
import API from '../../utils/API'
import './style.css';
import FinalScoreWaiting from "../../components/FinalScoreWaiting";
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1)); // Random index from 0 to i
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
  return array;
}

const MildyInfuriatedBird = (props) => {
  const gameContainerRef = useRef(null);
    let game;
    const { handleScoreChange,isGameOver,getReport} = props; // Destructure handleScoreChange from props
    const [phaserGame, setPhaserGame] = useState(null); // State to store the Phaser game instance
    const [score, setScore] = useState(0); // React state for score
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
    const[questionsPool,setQuestionsPool] = useState([]);
    const [questionSet, setQuestionSet] = useState({});
    const[nextQuestion,setNextQuestion] = useState({});
    const [answeredQuestions, setAnsweredQuestions] = useState(new Set());
    const [quizReport, setQuizReport] = useState({
      questions: [],       // Array to store questions and their data
      totalCorrect: 0,     // Counter for correct answers
      questionsAnswered: 0 // Counter for total questions answered
    });
  
    useEffect(()=>{
      if(isModalOpen){
        if(handleScoreChange){
          handleScoreChange(score);
        }    
      }
    },[isModalOpen])

    useEffect(() => {
      if (isGameOver) {
        handleScoreChange(score);
        getReport(quizReport); 

        handleGameEnd(); // Call the function when isGameOver becomes true
      }
    }, [isGameOver]);

    const handleGameEnd = () => {
      // Implement your game end logic here
      if (phaserGame) {
        // Destroy the Phaser game instance when the game is over
        phaserGame.destroy(true);
        setPhaserGame(null); // Clear the Phaser game instance from the state
      }
      
    };
  
    const handleAnswer = (answer) => {
      // Resume the game or proceed based on the answer
      if (answer === 'true') {
        setIsModalOpen(false);
    
        if (phaserGame) {
          // Access the scene manager and restart the scene
          const gameState = phaserGame.scene.getScene('GameState');
          if (gameState) {
            gameState.scene.restart('GameState');
    
            phaserGame.questionOpened = false;
          }
        }
      }
      
      // Log answered question
    
      // Use a functional state update to add the answered question
      setAnsweredQuestions(prev => {
        const newAnsweredQuestions = new Set(prev); // Create a new Set from previous state
        newAnsweredQuestions.add(nextQuestion.id); // Add the new answered question ID  
        // Select the next question based on the updated answered questions
        setNextQuestion(selectNextQuestion(newAnsweredQuestions)); // Pass the updated Set to selectNextQuestion
        return newAnsweredQuestions; // Return the updated Set
      });
    };
    
    const selectNextQuestion = (updatedAnsweredQuestions) => {
      // Filter out answered questions based on their IDs
      const unansweredQuestions = questionsPool.filter(
        question => !updatedAnsweredQuestions.has(question.id) // Use Set's has method
      );
    
      // If all questions have been answered, reset the answered questions
      if (unansweredQuestions.length === 0) {
        console.log("All questions answered, resetting.");
        setAnsweredQuestions(new Set()); // Reset answered questions
        return questionsPool[Math.floor(Math.random() * questionsPool.length)]; // Select a random question
      }
      // Select a random question from unanswered ones
      return unansweredQuestions[Math.floor(Math.random() * unansweredQuestions.length)];
    };
    
  useEffect(()=>{
    if(props.questionSet){
      setQuestionSet(props.questionSet);
      const shuffledQuestions = shuffleArray(props.questionSet.Questions); // Use slice to avoid mutating the original array
      let cleanedQuestions = []
      for (let i = 0; i < shuffledQuestions.length; i++) {
        if(shuffledQuestions[i].question){
          cleanedQuestions.push(shuffledQuestions[i])
        } 
      }
      setQuestionsPool(cleanedQuestions)
      const randomIndex = Math.floor(Math.random() * cleanedQuestions.length);
      // Set the selected question
      setNextQuestion(cleanedQuestions[randomIndex]);
      API.addPlayCount(props.questionSet.id)
    }else{
      
        API.getQuestionSet(10).then(res=>{
          setQuestionSet(res.data);
          const shuffledQuestions = shuffleArray(res.data.Questions); // Use slice to avoid mutating the original array
          let cleanedQuestions = []
          for (let i = 0; i < shuffledQuestions.length; i++) {
            if(shuffledQuestions[i].question){
              cleanedQuestions.push(shuffledQuestions[i])
            } 
          }
          setQuestionsPool(cleanedQuestions)
          const randomIndex = Math.floor(Math.random() * cleanedQuestions.length);
          // Set the selected question
          setNextQuestion(cleanedQuestions[randomIndex]);
        }).catch(err=>{
          console.log(err)
      })
    }
  },[])

  useEffect(() => {        
    document.body.style.backgroundColor = 'black';

    let gameConfig = {
      type: Phaser.CANVAS,
      width: 1240,
      height: 720,
      parent:gameContainerRef,
      scene: [BootState,PreloadState, MainMenuState,GameState], 
      physics: {
        default: 'arcade',
        arcade: {
          debug: false,
          gravity: { y: 1000 }
        }
      }, 
    }
    game = new Phaser.Game(gameConfig);
    game.settings = {
      lockOrientation: true,
      displayOrientation: 'landscape',
      storagePrefix: 'mif11_'
    };
    
    game.reactSetScore = setScore;
    // game.reactScore = score;
    game.openQuestion = openQuestion; // Expose modal control to Phaser
    setPhaserGame(game)
    // Clean up on unmount
    return () => {
      if (phaserGame) {
        phaserGame.destroy(true);
        setPhaserGame(null);
      }
      document.body.style.backgroundColor = ''; // Set back to default
    };
  }, []);
  
  const openQuestion= ()=>{
    setIsModalOpen(true);
    game.questionOpened = true
  }
  const recordAnswer = (answer) => {
    setQuizReport((prev) => {
      // Add the question and selected answer to the questions array
      const updatedQuestions = [
        ...prev.questions,
        { ...nextQuestion, selectedAnswer: answer }
      ];
  
      // Update totalCorrect if the answer is correct
      const isCorrect = answer.correctAnswer=== "true";
      return {
        ...prev,
        questions: updatedQuestions,
        questionsAnswered: prev.questionsAnswered + 1,
        totalCorrect: isCorrect ? prev.totalCorrect + 1 : prev.totalCorrect
      };
    });
  };
  return (
    <div style={{overflow:'hidden', background:'black'}} className="h-100 w-100" >
      {isGameOver? 
      <div className="vertical-center waiting-room" style={{minHeight:"100vh"}}>
      <FinalScoreWaiting></FinalScoreWaiting>
      </div>
      :
      <>
      <div ref={gameContainerRef} id="mildlyinfuriatingbird" />
      <QuizModal 
        isOpen={isModalOpen} 
        onRequestClose={() => setIsModalOpen(false)} 
        onAnswer={handleAnswer} 
        question={nextQuestion}
        recordAnswer={recordAnswer}
      />
      </>   
      }
    </div>
  );

};

export default MildyInfuriatedBird;