// Spawner.js
import Phaser from 'phaser';
import Monster from '../monsters/monster'; // Import the core Monster class
import monsterData from '../monsters/monsterData'; // Import monster configurations
import { spawnData } from './spawnData'; // Import the spawn dataset

export default class Spawner {
    constructor(scene, monsterGroup, player) {
        this.scene = scene;
        this.monsterGroup = monsterGroup;
        this.player = player;
    }

    /**
     * Dynamically determines spawn location and triggers cluster spawning.
     */
    spawnMonster() {
        const camera = this.scene.cameras.main; // Get the camera
        const worldView = camera.worldView; // Get the visible area of the camera
        const screenWidth = worldView.width;
        const screenHeight = worldView.height;
        const viewX = worldView.x;
        const viewY = worldView.y;
    
        // Define the margin for off-screen spawning
        const spawnMargin = 200;
    
        // Randomly choose a direction to spawn from (top, bottom, left, right)
        const spawnSide = Phaser.Math.Between(0, 3); // 0 = left, 1 = right, 2 = top, 3 = bottom
        let spawnX, spawnY;
    
        if (spawnSide === 0) { // Spawn on the left
            spawnX = viewX - spawnMargin; // Further outside the left edge
            spawnY = Phaser.Math.Between(viewY, viewY + screenHeight);
        } else if (spawnSide === 1) { // Spawn on the right
            spawnX = viewX + screenWidth + spawnMargin; // Further outside the right edge
            spawnY = Phaser.Math.Between(viewY, viewY + screenHeight);
        } else if (spawnSide === 2) { // Spawn on the top
            spawnX = Phaser.Math.Between(viewX, viewX + screenWidth);
            spawnY = viewY - spawnMargin; // Further outside the top edge
        } else { // Spawn on the bottom
            spawnX = Phaser.Math.Between(viewX, viewX + screenWidth);
            spawnY = viewY + screenHeight + spawnMargin; // Further outside the bottom edge
        }
    
        // Dynamically select monster types based on logic (example uses level)
        const monstersToSpawn = this.getMonstersForLevel(); // Get array of monster types
        this.spawnMonstersInLine(monstersToSpawn, spawnX, spawnY, spawnSide); // Spawn cluster at determined position

    }
    
    /**
     * Determines which monsters to spawn based on player level or other logic.
     * @returns {string[]} Array of monster types to spawn.
     */
    getMonstersForLevel() {
        const level = this.player.level; // Assuming the player object has a `level` property
        
        // Determine the appropriate level range for spawning
        let levelRange;
        if (level >= 1 && level <= 5) {
            levelRange = '1-5';
        } else if (level >= 6 && level <= 10) {
            levelRange = '6-10';
        } else if (level >= 11 && level <= 15) {
            levelRange = '11-15';
        } else if (level >= 16 && level <= 20) {
            levelRange = '16-20';
        } else if (level >= 21 && level <= 25) {
            levelRange = '21-25';
        } else if (level >= 26 && level <= 30) {
            levelRange = '26-30';
        } else if (level >= 31 && level <= 35) {
            levelRange = '31-35';
        } else if (level >= 36 && level <= 40) {
            levelRange = '36-40';
        } else if (level >= 41 && level <= 45) {
            levelRange = '41-45';
        } else if (level >= 46 && level <= 50) {
            levelRange = '46-50';
        } else if (level >= 51 && level <= 55) {
            levelRange = '51-55';
        } else if (level >= 56 && level <= 60) {
            levelRange = '56-60';
        } else if (level >= 61 && level <= 65) {
            levelRange = '61-65';
        } else if (level >= 66 && level <= 70) {
            levelRange = '66-70';
        } else {
            // For level 71 and above, use 'endless' mode
            levelRange = '71+';
        }
    
        // Return the monsters for the current level range
        return spawnData[levelRange];
    }
    
    /**
     * Spawns a cluster of monsters in a loose group.
     * @param {string[]} monsterTypes - List of monster types to randomly select from.
     * @param {number} baseX - X-coordinate for the base position of the cluster.
     * @param {number} baseY - Y-coordinate for the base position of the cluster.
     */
    spawnSingleMonster(monstersToSpawn, spawnX, spawnY) {
        const monsterConfig = monstersToSpawn[Phaser.Math.Between(0, monstersToSpawn.length - 1)];

        const config = monsterData[monsterConfig.type];

        if (!config) return;

        const monster = new Monster(this.scene, spawnX, spawnY, config.texture, config);
        this.monsterGroup.add(monster);

        // Assign movement behavior
        monster.movement = monsterConfig.movement;
        this.assignMovementBehavior(monster);
    }

    /**
     * Spawns monsters in a straight line from left to right.
     * @param {number} startX - Starting X-coordinate.
     * @param {number} startY - Y-coordinate for the line formation.
     * @param {number} monsterCount - The number of monsters to spawn in the line.
     * @param {number} spacing - The spacing between each monster in the line.
     */
    spawnMonstersInLine(monstersToSpawn, spawnX, spawnY, spawnSide) {
        // Randomly determine the number of monsters to spawn (between 5 and 10)
        const monsterCount = Phaser.Math.Between(2, 10);
    
        // Randomly determine the spacing between monsters (between 10 and 30px)
        const spacing = Phaser.Math.Between(30, 100);
    
        if (spawnSide === 2 || spawnSide === 3) {
            // Spawn monsters in a horizontal line (top or bottom)
            for (let i = 0; i < monsterCount; i++) {
                const monsterConfig = monstersToSpawn[Phaser.Math.Between(0, monstersToSpawn.length - 1)];
                const config = monsterData[monsterConfig.type]; // Get the monster's full configuration
    
                if (!config) continue; // Skip if no configuration is found
    
                // Calculate the spawn position (x-axis increases as we go right)
                const newX = spawnX + (i * spacing);
                const newY = spawnY;
    
                // Create the monster and add it to the group
                const monster = new Monster(this.scene, newX, newY, config.texture, config);
                this.monsterGroup.add(monster);
    
                // Assign the movement behavior for the monster
                monster.movement = monsterConfig.movement;
                this.assignMovementBehavior(monster);
            }
        } else if (spawnSide === 0 || spawnSide === 1) {
            // Spawn monsters in a vertical line (left or right)
            for (let i = 0; i < monsterCount; i++) {
                const monsterConfig = monstersToSpawn[Phaser.Math.Between(0, monstersToSpawn.length - 1)];
                const config = monsterData[monsterConfig.type]; // Get the monster's full configuration
    
                if (!config) continue; // Skip if no configuration is found
    
                // Calculate the spawn position (y-axis increases as we go down)
                const newX = spawnX;
                const newY = spawnY + (i * spacing);
    
                // Create the monster and add it to the group
                const monster = new Monster(this.scene, newX, newY, config.texture, config);
                this.monsterGroup.add(monster);
    
                // Assign the movement behavior for the monster
                monster.movement = monsterConfig.movement;
                this.assignMovementBehavior(monster);
            }
        }
    }
        spawnCluster(monsterDataArray, baseX, baseY) {
        if (!monsterDataArray || monsterDataArray.length === 0) return;

        const clusterSize = Phaser.Math.Between(5, 10);

        for (let i = 0; i < clusterSize; i++) {
            // Choose a random monster from the provided data array
            const randomIndex = Phaser.Math.Between(0, monsterDataArray.length - 1);
            const monsterConfig = monsterDataArray[randomIndex];
            const config = monsterData[monsterConfig.type]; // Get the full monster config

            if (!config) continue;

            const offsetX = Phaser.Math.Between(-100, 100);
            const offsetY = Phaser.Math.Between(-100, 100);

            const monster = new Monster(this.scene, baseX + offsetX, baseY + offsetY, config.texture, config);
            this.monsterGroup.add(monster);

            // Assign initial state and behavior based on movement type
            monster.movement = monsterConfig.movement;
            this.assignMovementBehavior(monster);
        }
    }
        assignMovementBehavior(monster) {
            
        switch (monster.movement) {
            case 'following':
                monster.state = 'following';
                monster.update = () => this.followPlayer(monster);
                break;
    
            case 'patrolling':
                monster.state = 'patrolling';
                monster.update = () => this.patrol(monster);
                break;
    
            case 'transition':
                monster.state = 'following'; // Start with patrolling
                this.setupStateCycle(monster);
                monster.update = () => {
                    if (monster.state === 'patrolling') {
                        this.patrol(monster);
                    } else if (monster.state === 'following') {
                        this.followPlayer(monster);
                    }
                };
                break;
    
            default:
                console.warn(`Unknown movement type: ${monster.movement}, defaulting to following.`);
                monster.state = 'following';
                monster.update = () => this.followPlayer(monster);
                break;
                    }
    }
    
    setupStateCycle(monster) {
        const patrolDuration = Phaser.Math.Between(2000, 4000); // 2-4 seconds patrolling
        const followDuration = Phaser.Math.Between(4000, 10000); // 3-5 seconds following
    
        // Switches to following after the patrol duration ends
        const switchToFollowing = () => {
            monster.state = 'following';
            this.scene.time.addEvent({
                delay: followDuration,
                callback: switchToPatrolling,
            });
        };
    
        // Switches back to patrolling after the follow duration ends
        const switchToPatrolling = () => {
            monster.state = 'patrolling';
            this.scene.time.addEvent({
                delay: patrolDuration,
                callback: switchToFollowing,
            });
        };
    
        // Start the cycle by patrolling initially
        this.scene.time.addEvent({
            delay: patrolDuration,
            callback: switchToFollowing,
        });
    }
        
    followPlayer(monster) {
        if (monster.knockback) return;

        const direction = new Phaser.Math.Vector2(this.player.x - monster.x, this.player.y - monster.y);
        direction.normalize();
        monster.setVelocity(direction.x * monster.speed, direction.y * monster.speed);
        monster.setRotation(0);
        
    }
    patrol(monster) {
        if (monster.knockback) return;
        if (monster.patrolDestination) {
            // Move towards the patrol destination
            const direction = new Phaser.Math.Vector2(
                monster.patrolDestination.x - monster.x,
                monster.patrolDestination.y - monster.y
            );
            direction.normalize();
            monster.setVelocity(direction.x * monster.speed, direction.y * monster.speed);
    
            // Check if the monster has reached the patrol destination
            if (Phaser.Math.Distance.Between(monster.x, monster.y, monster.patrolDestination.x, monster.patrolDestination.y) < 10) {
                // Choose a new patrol destination after reaching the current one
                monster.patrolDestination = this.getRandomPatrolDestination();
            }
        } else {
            // Set an initial patrol destination if none exists
            monster.patrolDestination = this.getRandomPatrolDestination();
        }
    }
        
    getRandomPatrolDestination() {
        const worldBounds = this.scene.physics.world.bounds;
        return {
            x: Phaser.Math.Between(worldBounds.x, worldBounds.x + worldBounds.width),
            y: Phaser.Math.Between(worldBounds.y, worldBounds.y + worldBounds.height),
        };
    }
        update() {
        this.monsterGroup.getChildren().forEach(monster => {
            if (monster.update) {
                monster.update(); // Calls the custom update method for each monster
            }
        });
    }
    
}
