import Phaser from "phaser";

export default class Laser {
    constructor(scene, player, monsters, config) {
        this.scene = scene;           // Phaser scene
        this.player = player;         // Player reference 
        this.monsters = monsters;     // Monster group
        this.config = { ...config };  // Copy of the configuration
        this.type = 'laser';          // Identifier for this skill
        this.canShoot = true;         // Track cooldown state
        this.projectiles = this.scene.physics.add.group(); // Group for laser projectiles

        // Add collision handling for projectiles and monsters
        this.scene.physics.add.overlap(this.projectiles, this.monsters, this.handleHit, null, this);
    }

    update() {
        if (!this.canShoot) return;

        const targets = this.findClosestMonsters(this.config.numLasers);
        if (targets.length > 0) {
            this.shoot(targets);
            this.setCooldown();
        }

        // Update projectiles' position and destroy them if they exceed range
        this.projectiles.getChildren().forEach(laser => {
            this.updateLaserRange(laser);
        });
    }

    shoot(targets) {
        targets.forEach(target => {
            // Create a new laser sprite with physics enabled
            const laser = this.scene.physics.add.sprite(this.player.x, this.player.y, 'laser');
            laser.setScale(0.5);  // Keep the visual scale as 0.5
            this.projectiles.add(laser);

            // Initialize hit count and distance tracking for the laser
            laser.hitCount = 0;
            laser.hitMonsters = new Set(); // Use a Set to track hit monsters
            laser.startX = this.player.x; // Starting x position
            laser.startY = this.player.y; // Starting y position
            laser.distanceTraveled = 0;

            // Ensure the physics body exists and adjust its size
            if (laser.body) {
                laser.body.setSize(50, 50); // Width and height of the collision box
            }

            // Rotate the laser to face the target
            const angle = Phaser.Math.Angle.Between(this.player.x, this.player.y, target.x, target.y);
            laser.rotation = angle;

            // Move the laser toward the target
            this.scene.physics.moveTo(laser, target.x, target.y, 600); // Adjust speed as needed
        });
    }

    setCooldown() {
        this.canShoot = false;
        this.scene.time.delayedCall(this.config.attackSpeed, () => {
            this.canShoot = true;
        });
    }

    upgradeStat(stat, value) {
        if (this.config[stat] !== undefined) {
            this.config[stat] += value;
        }
    }

    findClosestMonsters(count) {
        // Sort monsters by distance
        const monstersInRange = this.monsters.getChildren()
            .map(monster => ({
                monster,
                distance: Phaser.Math.Distance.Between(this.player.x, this.player.y, monster.x, monster.y),
            }))
            .filter(({ distance }) => distance <= this.config.range)
            .sort((a, b) => a.distance - b.distance);

        // Return the closest `count` monsters
        return monstersInRange.slice(0, count).map(entry => entry.monster);
    }

    updateLaserRange(laser) {
        // Calculate distance traveled from the start point
        const dx = laser.x - laser.startX;
        const dy = laser.y - laser.startY;
        laser.distanceTraveled = Math.sqrt(dx * dx + dy * dy);

        // Destroy the laser if it exceeds its range
        if (laser.distanceTraveled >= this.config.range) {
            laser.destroy();
        }
    }

    handleHit(projectile, monster) {
        // Ensure the laser has not already hit this monster
        if (projectile.hitMonsters.has(monster)) {
            return;
        }

        // Mark the monster as hit by this laser
        projectile.hitMonsters.add(monster);

        // Deal damage to the monster
        const damage = this.config.damage;
        monster.takeDamage(damage);

        // Increment the laser's hit count
        projectile.hitCount += 1;

        // Check if the laser has hit its maximum targets
        if (projectile.hitCount >= this.config.targets) {
            projectile.destroy();
        }
    }
}
