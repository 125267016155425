import Phaser from 'phaser';
import GameOver from './GameOver'; // Ensure you have the GameOver class imported
import Pipe from './obstacles/Pipe';
class Bird extends Phaser.Physics.Arcade.Sprite {
  constructor(scene, x, y) {
    super(scene, x, y, 'atlas', '');

    // Add the sprite to the scene
    scene.add.existing(this);
    scene.physics.add.existing(this);
    // this.body.setImmovable(true);

    this.alive = true;
    this.score = 0;
    this.swingVelocity = -450;
    this.ANGLE = Phaser.Math.DegToRad(20); // Convert degrees to radians
    this.isTapping = false; // Flag to indicate tapping state
    // this.crash = this.crash.bind(this); // Bind context

    // Set up animations
    const framePrefix = 'birds/' + 5 + '/bird_';

    const animationFrames = this.anims.generateFrameNames('atlas', {
      prefix: framePrefix,
      start: 0,
      end: 3
    });

    this.anims.create({
      key: 'idle',
      frames: animationFrames,
      frameRate: 15,
      repeat: -1
    });

    this.play('idle');

    // Set up physics
    this.setSize(this.width - 20, this.height - 20, 0, -5);
    this.setCollideWorldBounds(true);
    this.setBounce(0.2);
    // Add an event listener for when the bird hits the world bounds
    this.body.onWorldBounds = true; // Enable world bounds events
    this.scene.physics.world.on('worldbounds', (body) => {
      if (body.gameObject === this) {
        this.crash(body);
      }
    });

    // Input event
    scene.input.on('pointerdown', this.tap, this);
    scene.input.keyboard.on('keydown-SPACE', this.tap, this); // Simple event listener for spacebar

  }

  update() {
    // Reset rotation to 0 if not tapping
    if (!this.isTapping) {
      this.rotation = 0;
    } else {
      // Tilt the bird based on velocity while tapping
      this.rotation = this.body.velocity.y < 0 ? -this.ANGLE : this.ANGLE;
    }

    if (this.swingVelocity !== 0 && this.y < -50) this.swingVelocity = 0;
  }

  tap() {
    if (this.swingVelocity !== 0) {
      this.isTapping = true; // Set flag to true when tapped
      this.body.setVelocityY(this.swingVelocity);
      this.update(); // Manually call update() for immediate effect
      this.scene.game.audio.playSound('sndWings');
      // this.scene.scene.resume()
      // this.scene.sound.play('sndWings'); // Use scene.sound for playing sounds
      // Reset the tap flag after a short delay
      // this.scene.time.delayedCall(300, () => {
      //   this.isTapping = false; // Reset the tapping state
      //   this.update(); // Manually call update() for immediate effect
      // });
    }
  }


  crash(body1, body2) {
    // body1.setVelocity(0); // Stop any movement
    // body1.setAngularVelocity(0); // Stop any rotation
    // body1.x=100;
    // body1.y=100;

    let scene=this.scene
    body1.destroy();

    scene.input.off('pointerdown', this.tap, this); // Turn off input event listener
    scene.input.keyboard.off('keydown-SPACE', this.tap, this); // Simple event listener for spacebar
    scene.game.audio.playSound('sndBam'); // Use scene.sound for playing sounds
    scene.scene.pause() ;
    scene.physics.world.pause(); // Pauses physics updates
    scene.spawner.active = false;


    if (body2 && !body2.isGround) {
      body2.destroy(); // Destroy the pipe itself if needed
    }
    // // if (!this.alive) return;
    // // this.alive = false;
    // this.spawner.active = false;
    // this.destroy();
    scene.game.openQuestion();
    // // this.scene.game.audio.playSound('sndBam'); // Use scene.sound for playing sounds
    // const gameOver = new GameOver(scene, scene.score);
    // gameOver.preload(); // Preload assets
    // gameOver.create(); // Create UI elements
    // scene.scene.pause(); // Pauses the current scene

  }

  collect(body1, body2) {
    // let scene=this.scene

    body2.destroy()
    if (body2.gameObject) body2.gameObject.destroy();

    // let score = this.scene.game.reactScore;
    this.scene.score += 1
    this.scene.game.reactSetScore(this.scene.score)
    this.scene.textCoins.setText(this.scene.score);
    this.scene.game.audio.playSound('sndCoin'); // Use scene.sound for playing sounds
    // body1.score +=1;
    // if(body1.score == 10){
    //   let scene=this.scene

    //   body1.setVelocity(0); // Stop any movement
    //   body1.setAngularVelocity(0); // Stop any rotation
    //   body1.x=100;
    //   body1.y=100;
    //   body1.destroy();

    //   scene.input.off('pointerdown', this.tap, this); // Turn off input event listener
    //   scene.input.keyboard.off('keydown-SPACE', this.tap, this); // Simple event listener for spacebar
    //   scene.scene.pause() ;
    //   scene.physics.world.pause(); // Pauses physics updates
    //   scene.spawner.active = false;
    //   scene.game.openQuestion();
  
    // }
  }
  // collect(body1, body2) {
  //   body2.destroy();
  //   if (body2.gameObject) body2.gameObject.destroy();
  
  //   // Use a callback to ensure you have the most recent score
  //   if (this.scene.game.reactSetScore) {
  //     this.scene.game.reactSetScore(prevScore => {
  //       const newScore = prevScore + 1;
  
  //       // Update Phaser text display with the new score
  //       this.scene.textCoins.setText(newScore);
  
  //       // Play sound effect
  //       this.scene.game.audio.playSound('sndCoin');
  
  //       // Optionally update internal game score if needed
  //       this.scene.game.score = newScore;
  
  //       return newScore; // Update React state with the new score
  //     });
  //   }
  // }
  
}

export default Bird;
