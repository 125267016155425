import Phaser from "phaser";

export default class Boomerang {
    constructor(scene, player, monsters, config) {
        this.scene = scene;           // Phaser scene
        this.player = player;         // Player reference
        this.monsters = monsters;     // Monster group
        this.config = { ...config };  // Copy of the configuration
        this.type = 'boomerang';      // Identifier for this skill
        this.canShoot = true;         // Track cooldown state
        this.projectiles = this.scene.physics.add.group(); // Group for Boomerang projectiles

        // Add collision handling for projectiles and monsters
        this.scene.physics.add.overlap(this.projectiles, this.monsters, this.handleHit, null, this);

        // Add a collider for projectiles and the player with proper context binding
        this.scene.physics.add.overlap(this.projectiles, this.player, this.handleReturn.bind(this), null, this);
    }

    update() {
        // Update boomerang behavior for all projectiles
        this.projectiles.getChildren().forEach(boomerang => {
            this.updateBoomerang(boomerang);
        });
        if (!this.canShoot) return;

        const target = this.findClosestMonster();
        if (target) {
            this.shoot(target);
            this.setCooldown();
        }
    }

    shoot(target) {
        // Create a new Boomerang sprite with physics enabled
        const boomerang = this.scene.physics.add.sprite(this.player.x, this.player.y, 'boomerang01');
        boomerang.setScale(this.config.size);
        // Set the physics body size
        if (boomerang.body) {
            boomerang.body.setCircle(8); // Adjust collision box
            boomerang.body.setOffset(4, 4); // Adjust offset for alignment
        }

        // Add the Boomerang to the projectiles group
        this.projectiles.add(boomerang);

        // Play boomerang animation
        boomerang.anims.play('boomerangSpin', true);

        // Initialize properties for the Boomerang
        boomerang.startX = this.player.x;
        boomerang.startY = this.player.y;
        boomerang.distanceTraveled = 0;
        boomerang.returning = false;  // Whether the Boomerang is returning to the player
        boomerang.hitMonsters = new Set(); // Track hit monsters
        boomerang.destroyable = false;  // Set boomerang as not destroyable for the first 2 seconds

        // Rotate the boomerang to face the target
        const angle = Phaser.Math.Angle.Between(this.player.x, this.player.y, target.x, target.y);
        boomerang.rotation = angle;

        // Move the boomerang outward
        this.scene.physics.velocityFromRotation(angle, this.config.speed, boomerang.body.velocity);
    }

    updateBoomerang(boomerang) {
        const dx = boomerang.x - boomerang.startX;
        const dy = boomerang.y - boomerang.startY;
        boomerang.distanceTraveled = Math.sqrt(dx * dx + dy * dy);

        // Check if the Boomerang should return
        if (!boomerang.returning && boomerang.distanceTraveled >= this.config.range) {
            boomerang.returning = true;
            boomerang.hitMonsters = new Set(); // Track hit monsters
        }

        // Move the Boomerang back to the player's current position if returning
        if (boomerang.returning) {
            const angleToPlayer = Phaser.Math.Angle.Between(boomerang.x, boomerang.y, this.player.x, this.player.y);
            this.scene.physics.velocityFromRotation(angleToPlayer, this.config.speed, boomerang.body.velocity);
        }
    }

    handleReturn(player, boomerang) {
        // Only destroy the boomerang if it is in the returning state and it's destroyable
        if (boomerang.returning) {
            boomerang.destroy();
        }
    }

    setCooldown() {
        this.canShoot = false;
        this.scene.time.delayedCall(this.config.attackSpeed, () => {
            this.canShoot = true;
        });
    }

    upgradeStat(stat, value) {
        if (this.config[stat] !== undefined) {
            this.config[stat] += value;
        }
    }

    findClosestMonster() {
        // Find the closest monster within range
        const monstersInRange = this.monsters.getChildren()
            .map(monster => ({
                monster,
                distance: Phaser.Math.Distance.Between(this.player.x, this.player.y, monster.x, monster.y),
            }))
            .filter(({ distance }) => distance <= this.config.range)
            .sort((a, b) => a.distance - b.distance);

        // Return the closest monster
        return monstersInRange.length > 0 ? monstersInRange[0].monster : null;
    }

    handleHit(boomerang, monster) {
        // Ensure the Boomerang has not already hit this monster
        if (boomerang.hitMonsters.has(monster)) {
            return;
        }

        // Mark the monster as hit by this Boomerang
        boomerang.hitMonsters.add(monster);

        // Deal damage to the monster
        const damage = this.config.damage;
        monster.takeDamage(damage);
    }
}
