import Phaser from "phaser";

export default class Icicle {
    constructor(scene, player, monsters, config) {
        this.scene = scene;           // Phaser scene
        this.player = player;         // Player reference
        this.monsters = monsters;     // Monster group
        this.config = { ...config };  // Copy of the configuration
        this.type = 'icicle';         // Identifier for this skill
        this.canShoot = true;         // Track cooldown state
        this.projectiles = this.scene.physics.add.group(); // Group for icicle projectiles

        // Add collision handling for projectiles and monsters
        this.scene.physics.add.overlap(this.projectiles, this.monsters, this.handleHit, null, this);
    }

    update() {
        if (!this.canShoot) return;

        const targets = this.findClosestMonsters(1); // Only one primary target
        if (targets.length > 0) {
            this.shoot(targets[0]);
            this.setCooldown();
        }
    }

    shoot(target) {
        const numIcicles = this.config.numIcicles || 1; // Number of icicles in the barrage
        const spacing = 30; // Distance between each icicle in the barrage
    
        // Calculate the firing angle between the player and the target
        const angle = Phaser.Math.Angle.Between(this.player.x, this.player.y, target.x, target.y);
    
        for (let i = 0; i < numIcicles; i++) {
            // Offset perpendicular to the firing angle
            const offset = (i - (numIcicles - 1) / 2) * spacing;
            const offsetX = offset * Math.cos(angle + Math.PI / 2); // Perpendicular X offset
            const offsetY = offset * Math.sin(angle + Math.PI / 2); // Perpendicular Y offset
    
            // Create a new icicle projectile
            const icicle = this.scene.physics.add.sprite(
                this.player.x + offsetX,
                this.player.y + offsetY,
                'icicle'
            );
            icicle.setScale(0.5); // Adjust scale as needed
            this.projectiles.add(icicle);
    
            // Rotate the icicle to face the target
            icicle.rotation = angle;
    
            // Move the icicle toward the target
            const velocityX = Math.cos(angle) * this.config.speed;
            const velocityY = Math.sin(angle) * this.config.speed;
            icicle.body.setVelocity(velocityX, velocityY);
    
            // Destroy the icicle if it exceeds its range
            this.scene.time.delayedCall((this.config.range / this.config.speed) * 1000, () => {
                if (icicle.active) icicle.destroy();
            });
        }
    }
    

    setCooldown() {
        this.canShoot = false;
        this.scene.time.delayedCall(this.config.attackSpeed, () => {
            this.canShoot = true;
        });
    }

    upgradeStat(stat, value) {
        if (this.config[stat] !== undefined) {
            this.config[stat] += value;
        }
    }

    findClosestMonsters(count) {
        // Sort monsters by distance
        const monstersInRange = this.monsters.getChildren()
            .map(monster => ({
                monster,
                distance: Phaser.Math.Distance.Between(this.player.x, this.player.y, monster.x, monster.y),
            }))
            .filter(({ distance }) => distance <= this.config.range)
            .sort((a, b) => a.distance - b.distance);

        // Return the closest `count` monsters
        return monstersInRange.slice(0, count).map(entry => entry.monster);
    }

    handleHit(icicle, monster) {
        if (icicle.hitTarget) return; // Ensure each icicle hits only once

        // Mark the icicle as having hit its target
        icicle.hitTarget = true;

        // Deal damage to the monster
        const damage = this.config.damage;
        monster.takeDamage(damage);

        // Destroy the icicle immediately upon hitting
        icicle.destroy();
    }
}
