import Phaser from 'phaser';
import sound from '../assets/sound.png';
import silent from '../assets/silent.png';
import KaichouOnMp3 from '../assets/Sounds/Kaichou-On.wav';
import KaichouOnOgg from '../assets/Sounds/Kaichou-On.ogg';

export default class MainMenuState extends Phaser.Scene {
  constructor() {
    super({ key: 'MainMenuState' });
    this.soundOn = true;
  }

  init() {
    // Responsive scaling
    this.scale.scaleMode = Phaser.Scale.FIT;
    this.scale.autoCenter = Phaser.Scale.CENTER_BOTH;
  }

  preload() {
    // Load the sound toggle icons
    this.load.image('sound', sound);
    this.load.image('silent', silent);

    // Load background music in both formats for cross-device compatibility
    this.load.audio('KaichouOn', [KaichouOnOgg, KaichouOnMp3]);

    // Add loading bar elements
    this.createLoadingElements();
  }

  createLoadingElements() {
    const { width, height } = this.scale;

    // Create a loading bar and related elements
    this.progressBox = this.add.graphics();
    this.progressBox.fillStyle(0x222222, 0.8);
    this.progressBox.fillRect(width / 4, height / 2 - 30, width / 2, 50);

    this.progressBar = this.add.graphics();

    this.loadingText = this.add.text(width / 2, height / 2 - 50, 'Loading...', {
      fontSize: '20px',
      color: '#ffffff',
    }).setOrigin(0.5);

    this.percentText = this.add.text(width / 2, height / 2, '0%', {
      fontSize: '18px',
      color: '#ffffff',
    }).setOrigin(0.5);

    // Update progress bar during loading
    this.load.on('progress', (value) => {
      this.percentText.setText(`${Math.floor(value * 100)}%`);
      this.progressBar.clear();
      this.progressBar.fillStyle(0xffffff, 1);
      this.progressBar.fillRect(
        width / 4 + 10,
        height / 2 - 20,
        (width / 2 - 20) * value,
        30
      );
    });

    // Clear elements when loading completes
    this.load.on('complete', () => {
      this.progressBox.destroy();
      this.progressBar.destroy();
      this.loadingText.destroy();
      this.percentText.destroy();
    });

    // Resize elements if the window size changes
    this.scale.on('resize', this.onResize, this);
  }

  onResize(gameSize) {
    const { width, height } = gameSize;

    // Update positions and sizes of loading elements
    if (this.progressBox && this.progressBar && this.loadingText && this.percentText) {
      this.progressBox.clear();
      this.progressBox.fillStyle(0x222222, 0.8);
      this.progressBox.fillRect(width / 4, height / 2 - 30, width / 2, 50);

      this.loadingText.setPosition(width / 2, height / 2 - 50);
      this.percentText.setPosition(width / 2, height / 2);

      const progressValue = parseFloat(this.percentText.text) / 100 || 0;
      this.progressBar.clear();
      this.progressBar.fillStyle(0xffffff, 1);
      this.progressBar.fillRect(
        width / 4 + 10,
        height / 2 - 20,
        (width / 2 - 20) * progressValue,
        30
      );
    }
  }

  create() {
    const { width, height } = this.scale;

    // Unlock audio on iOS devices
    this.input.once('pointerdown', () => {
      if (this.sound.context.state === 'suspended') {
        this.sound.context.resume();
      }
    });

    // Create a "Click or Press Space to Start" button in the center
    this.startButton = this.add.text(width / 2, height / 2, 'Click or Press Space to Start', {
      fontSize: '32px',
      fill: '#fff',
    }).setOrigin(0.5);

    // Set up the click event to start the game
    this.startButton.setInteractive();
    this.startButton.on('pointerdown', () => {
      if (!this.bgMusic) {
        this.bgMusic = this.sound.add('KaichouOn', { loop: true, volume: 0.3 });
        this.bgMusic.play();
      }
      this.scene.start('GameState');
    });

    // Add a keyboard event to start the game with the spacebar
    this.input.keyboard.on('keydown-SPACE', () => {
      this.scene.start('GameState');
    });

    // Create the sound toggle button in the top-right corner
    this.soundButton = this.add.image(width - 40, 40, 'sound').setInteractive();
    this.soundButton.setScale(0.7);

    // Add click event to toggle sound
    this.soundButton.on('pointerdown', () => {
      this.toggleSound();
    });

    // Handle resizing for UI elements
    this.scale.on('resize', this.onResizeCreate, this);
  }

  onResizeCreate(gameSize) {
    const { width, height } = gameSize;

    if (this.startButton) {
      this.startButton.setPosition(width / 2, height / 2);
    }

    if (this.soundButton) {
      this.soundButton.setPosition(width - 40, 40);
    }
  }

  toggleSound() {
    if (this.soundOn) {
      this.soundButton.setTexture('silent');
      this.sound.mute = true;
    } else {
      this.soundButton.setTexture('sound');
      this.sound.mute = false;
    }
    this.soundOn = !this.soundOn;
  }
}
