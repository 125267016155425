import Phaser from 'phaser';
import upgradeOptions from './upgradeOptions';

export default class LevelUpModal {
    constructor(scene) {
        this.scene = scene;
        this.modalElements = [];
        this.screenWidth = this.scene.cameras.main.width;
        this.screenHeight = this.scene.cameras.main.height;
        this.modalWindow = this.createModalWindow(); // Store the modalWindow
        this.choices =this.generateChoices();
        this.showing=false;
    }

    show() {
        // Close any existing modal elements before creating new ones
        this.close();
        this.showing=true;
        // Recalculate the screen dimensions
        this.screenWidth = this.scene.cameras.main.width;
        this.screenHeight = this.scene.cameras.main.height;
    
        // Pause the game
        this.pauseGame();
    
        // Re-create modal window
        this.modalWindow = this.createModalWindow();
    
        // Recreate the background
        this.createBackground();
    
        // Add title text
        this.addTitleText(this.modalWindow);
    
        // Generate new choices every time show is triggered
        this.choices = this.generateChoices();
    
        // Display the new choices
        this.displayChoices(this.choices, this.modalWindow);
    }
    pauseGame() {
        this.scene.physics.pause();
        this.scene.time.paused = true;
    }

    resumeGame() {
        this.scene.physics.resume();
        this.scene.time.paused = false;
    }

    onResize(screenWidth, screenHeight) {
        // Store the new screen dimensions
        this.screenWidth = screenWidth;
        this.screenHeight = screenHeight;

        // Update modal elements to match the new screen size
        this.modalElements.forEach(element => {
            if (element.updateOnResize) {
                element.updateOnResize(this.screenWidth, this.screenHeight);
            }
        });
        if(this.showing){
            this.displayChoices(this.choices, this.modalWindow);
        }

    }

    createBackground() {
        const modalBg = this.scene.add.rectangle(
            this.screenWidth / 2,
            this.screenHeight / 2,
            this.screenWidth,
            this.screenHeight,
            0x000000,
            0.5 // Semi-transparent
        ).setScrollFactor(0);
    
        modalBg.setDepth(0); // Ensure the background is behind other elements
    
        modalBg.updateOnResize = (screenWidth, screenHeight) => {
            modalBg.setPosition(screenWidth / 2, screenHeight / 2);
            modalBg.setSize(screenWidth, screenHeight);
        };
    
        this.modalElements.push(modalBg);
    }
    
    createModalWindow() {
        const modalAspectRatio = 5 / 4;
        const widthFactor = 0.6;
        const heightFactor = widthFactor / modalAspectRatio;
        const minModalWidth = 400;
        const minModalHeight = 300;
        const maxModalWidth = 600;
        const maxModalHeight = 400;
    
        let modalWidth = Math.min(Math.max(this.screenWidth * widthFactor, minModalWidth), maxModalWidth);
        let modalHeight = Math.min(Math.max(this.screenHeight * heightFactor, minModalHeight), maxModalHeight);
    
        const modalWindow = this.scene.add.rectangle(
            this.screenWidth / 2,
            this.screenHeight / 2,
            modalWidth,
            modalHeight,
            0xa8a895 // White color
        ).setScrollFactor(0).setStrokeStyle(2, 0x000000);
    
        modalWindow.setDepth(2); // Ensure the modal window is above the background
    
        modalWindow.updateOnResize = (screenWidth, screenHeight) => {
            modalWidth = Math.min(Math.max(screenWidth * widthFactor, minModalWidth), maxModalWidth);
            modalHeight = Math.min(Math.max(screenHeight * heightFactor, minModalHeight), maxModalHeight);
    
            modalWindow.setPosition(screenWidth / 2, screenHeight / 2);
            modalWindow.setSize(modalWidth, modalHeight);
        };
    
        this.modalElements.push(modalWindow);
    
        return modalWindow;
    }
        addTitleText(modalWindow) {
        if (!modalWindow) {
            console.error('modalWindow is undefined or null');
            return;
        }
    
        // Calculate title position: 20% from the top of the modal
        const modalCenterX = modalWindow.x;
        const modalCenterY = modalWindow.y;
        const modalHeight = modalWindow.height;
    
        const titleY = modalCenterY - modalHeight / 2 + modalHeight * 0.2;
    
        const titleText = this.scene.add.text(
            modalCenterX,
            titleY,
            this.hasActiveSkills() ? 'Upgrade or Choose a New Skill!' : 'Choose a Skill!',
            {
                fontSize: `${Math.min(modalWindow.width / 20, 32)}px`, // Scalable font size
                color: '#000',
                fontStyle: 'bold',
                align: 'center',
            }
        ).setOrigin(0.5).setScrollFactor(0);
    
        // Handle resizing
        titleText.updateOnResize = () => {
            const updatedTitleY = modalWindow.y - modalWindow.height / 2 + modalWindow.height * 0.2;
            titleText.setPosition(modalWindow.x, updatedTitleY);
            titleText.setFontSize(`${Math.min(modalWindow.width / 20, 32)}px`);
        };
        titleText.setDepth(3);
        this.modalElements.push(titleText);
    }
                
    hasActiveSkills() {
        return this.scene.attackManager.activeSkills.length > 0;
    }

    generateChoices() {
        const activeSkills = this.scene.attackManager.activeSkills;
        const allSkills = Object.keys(this.scene.attackManager.skillConfigs);
        const unactivatedSkills = allSkills.filter(
            (skill) => !activeSkills.some((activeSkill) => activeSkill.type === skill)
        );

        let choices = [];

        if (this.hasActiveSkills()) {
            activeSkills.forEach(skill => {
                const upgrades = this.generateUpgradeOptions(skill);
                choices.push(...upgrades);
            });
        }

        if (unactivatedSkills.length > 0) {
            const newSkillChoices = this.generateSkillChoices(unactivatedSkills);
            choices.push(...newSkillChoices);
        }

        return Phaser.Utils.Array.Shuffle(choices).slice(0, 3);
    }

    generateSkillChoices(unactivatedSkills) {
        const formatSkillName = (skillType) => {
            // Convert camelCase to "Title Case" with spaces
            return skillType
                .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space before capital letters
                .replace(/^./, str => str.toUpperCase()); // Capitalize the first letter
        };
    
        return Phaser.Utils.Array.Shuffle(unactivatedSkills).slice(0, 3).map(skillType => ({
            text: `Unlock ${formatSkillName(skillType)}`,
            effect: () => this.scene.attackManager.activateSkill(skillType),
            iconKey: `${skillType}_icon`,
        }));
    }
    
    generateUpgradeOptions(skill) {
        return (upgradeOptions[skill.type] || [])
            .filter(upgrade => skill.config[upgrade.stat] + upgrade.value <= upgrade.max)
            .map(upgrade => ({
                text: upgrade.description,
                effect: () => skill.upgradeStat(upgrade.stat, upgrade.value),
                iconKey: upgrade.iconKey,
            }));
    }

    displayChoices(choices, modalWindow) {
        const modalX = modalWindow.x;
        const modalY = modalWindow.y;
        const modalWidth = modalWindow.width;
        const modalHeight = modalWindow.height;
    
        // Calculate dynamic Y positions for buttons
        const choiceAreaHeight = modalHeight * 0.70;
        const startY = modalY - choiceAreaHeight / 2;
        const buttonSpacing = choiceAreaHeight / (choices.length );
    
        const buttonWidth = modalWidth * 0.80;
        const buttonHeight = modalWidth / 10;
        const buttonX = modalX - buttonWidth / 2;
    
        choices.forEach((choice, index) => {
            const y = startY + buttonSpacing * (index + 1);
            const isUpgrade = choice.text.startsWith('Unlock');
            const borderColor = isUpgrade ? 0xffd700 : 0x00ff00;
    
            const buttonGraphics = this.scene.add.graphics().setScrollFactor(0);
    
            // Draw a rounded rectangle for the button background
            buttonGraphics.fillStyle(0x586775, 1);
            buttonGraphics.fillRoundedRect(buttonX, y - buttonHeight / 2, buttonWidth, buttonHeight, 20);
            buttonGraphics.lineStyle(4, borderColor, 1);
            buttonGraphics.strokeRoundedRect(buttonX, y - buttonHeight / 2, buttonWidth, buttonHeight, 20);
    
            // Center icon inside the button
            const icon = this.scene.add.image(
                buttonX + buttonWidth * 0.1,
                y,
                choice.iconKey
            )
                .setScale(0.5)
                .setScrollFactor(0)
                .setOrigin(0.5);
    
            // Calculate dynamic font size
            const fontSize = Math.min(
                Math.max(Math.floor(this.scene.scale.width / 60), 16), // Minimum font size of 16
                24 // Maximum font size of 32
            );
            
            // Center text inside the button
            const buttonText = this.scene.add.text(
                buttonX + buttonWidth * 0.5,
                y,
                choice.text,
                {
                    fontSize: `${fontSize}px`,
                    color: '#ffffff',
                    wordWrap: { width: buttonWidth * 0.8, useAdvancedWrap: true },
                    align: 'center',
                }
            )
                .setOrigin(0.5)
                .setScrollFactor(0);
    
            // Set interactive area and add event listeners
            buttonGraphics.setInteractive(
                new Phaser.Geom.Rectangle(buttonX, y - buttonHeight / 2, buttonWidth, buttonHeight),
                Phaser.Geom.Rectangle.Contains
            );
    
            const redrawButton = () => {
                buttonGraphics.clear();
                buttonGraphics.fillStyle(0x586775, 1);
                buttonGraphics.fillRoundedRect(buttonX, y - buttonHeight / 2, buttonWidth, buttonHeight, 20);
                buttonGraphics.lineStyle(4, borderColor, 1);
                buttonGraphics.strokeRoundedRect(buttonX, y - buttonHeight / 2, buttonWidth, buttonHeight, 20);
            };
    
            // Event listeners
            const addListeners = () => {
                buttonGraphics.on('pointerover', () => {
                    buttonGraphics.clear();
                    buttonGraphics.fillStyle(0x475664, 1);
                    buttonGraphics.fillRoundedRect(buttonX, y - buttonHeight / 2, buttonWidth, buttonHeight, 20);
                    buttonGraphics.lineStyle(4, borderColor, 1);
                    buttonGraphics.strokeRoundedRect(buttonX, y - buttonHeight / 2, buttonWidth, buttonHeight, 20);
                    this.scene.input.setDefaultCursor('pointer');
                });
    
                buttonGraphics.on('pointerout', () => {
                    redrawButton();
                    this.scene.input.setDefaultCursor('default');
                });
    
                buttonGraphics.on('pointerdown', () => {
                    choice.effect();
                    this.close();
                    this.scene.input.setDefaultCursor('default');
                });
            };
    
            addListeners();
            buttonGraphics.setDepth(3);
            icon.setDepth(3);
            buttonText.setDepth(3);
            
            // Update logic for resizing
            buttonGraphics.updateOnResize = (screenWidth, screenHeight) => {
                buttonGraphics.clear();
                buttonGraphics.removeAllListeners(); // Remove all event listeners
                icon.destroy();
                buttonText.destroy();
                
             };
    
            this.modalElements.push(buttonGraphics, icon, buttonText);
        });
    }
    

    close() {
        this.modalElements.forEach(element => element.destroy());
        this.modalElements = [];
        this.showing=false;
        this.resumeGame();

        if (this.scene.updateExpBar) {
            this.scene.updateExpBar();
        }
    }
}
