import Phaser from 'phaser';
import Laser from '../skills/laser';
import Icicle from '../skills/Icicle'; // Import the Icicle class
import RingOfFire from '../skills/RingOfFire'; // Import the RingOfFire class
import skillConfigs from './skillsConfig';
import Shuriken from '../skills/Shuriken';
import Tornado from '../skills/Tornado';
import SpiritBall from '../skills/SpiritBall';
import Boomerang from '../skills/Boomerang';
import SuperStar from '../skills/SuperStar';

export default class AttackManager {
    constructor(scene, player, monsters) {
        this.scene = scene;      // Reference to the Phaser scene
        this.player = player;    // Reference to the player
        this.monsters = monsters; // Reference to the monster group
        this.skillConfigs = skillConfigs; // Ensure this line is in the AttackManager constructor
        this.activeSkills = [];  // List of currently active skills
        // this.activateSkill('spiritBall'); // Default skill activation

    }

    // Initialize skill timers and logic
    initialize() {
        this.scene.time.addEvent({
            delay: 100, // Update all skills frequently
            callback: this.updateSkills,
            callbackScope: this,  
            loop: true,
        });
    }

    // Dynamically activate a skill     
    activateSkill(skillType) {
        const skillConfig = skillConfigs[skillType]; // Fetch the config for the skill
        if (!skillConfig) {
            return;
        }

        // Prevent duplicate activation
        if (this.activeSkills.find(skill => skill.type === skillType)) {
            return;
        }

        // Dynamically create the skill instance
        let newSkill;
        if (skillType === 'laser') {
            newSkill = new Laser(this.scene, this.player, this.monsters, skillConfig);
        } else if (skillType === 'ringOfFire') {
            newSkill = new RingOfFire(this.scene, this.player, this.monsters, skillConfig);
        }else if (skillType === 'shuriken') {
            newSkill = new Shuriken(this.scene, this.player, this.monsters, skillConfig);
        } else if (skillType === 'icicle') {
            newSkill = new Icicle(this.scene, this.player, this.monsters, skillConfig);
        } else if (skillType === 'tornado') { 
            newSkill = new Tornado(this.scene, this.player, this.monsters, skillConfig);
        } else if (skillType === 'spiritBall') { 
            newSkill = new SpiritBall(this.scene, this.player, this.monsters, skillConfig);
        }else if (skillType === 'boomerang') { 
            newSkill = new Boomerang(this.scene, this.player, this.monsters, skillConfig);
        }
        // else if (skillType === 'superStar') { // Add logic for Icicle
        //     newSkill = new SuperStar(this.scene, this.player, this.monsters, skillConfig);
        // }
        else {
            return;
        }

        // Add the new skill to the active skills list
        if (newSkill) {
            this.activeSkills.push(newSkill);
        }
    }

    // Update all active skills
    updateSkills() {
        this.activeSkills.forEach(skill => skill.update());
    }

    // Upgrade stats for a specific skill
    upgradeSkill(skillType, stat, value) {
        const skill = this.activeSkills.find(skill => skill.type === skillType);
        if (skill) {
            skill.upgradeStat(stat, value);
        } else {
            console.error(`Skill ${skillType} is not active.`);
        }
    }
}
