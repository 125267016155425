import Phaser from 'phaser';
import globalOrientation from '../classes/utils/Orientation';
import Storage from '../classes/utils/Storage';
import AudioController from '../classes/utils/AudioController';
import preloadAtlas from '../assets/images/preload_atlas.png'
import preloadAtlasJson from '../assets/images/preload_atlas.json'

class BootState extends Phaser.Scene {
  constructor() {
    super({ key: 'BootState' });
  }

  init() {
    // Responsive scaling
    this.scale.scaleMode = Phaser.Scale.FIT;
    this.scale.autoCenter = Phaser.Scale.CENTER_BOTH;
  }

  preload() {
    this.load.atlas('preload', preloadAtlas, preloadAtlasJson);
  }

  create() {
    this.cameras.main.setBackgroundColor('#fff');

    const settings = this.game.settings;
    // Add orientation handler if needed
    if (settings.lockOrientation) {
      globalOrientation.init(this); // Initialize with the current scene context
    }


    // Add storage controller
    this.game.storage = new Storage(settings.storagePrefix);

    // Initialize AudioController and store it in the game instance
    this.game.audio = new AudioController(this);
    // Optionally, set default volume or other initial values
    // this.game.audio.switchVolumeSounds();
    // this.game.audio.switchVolumeMusic();

    this.game.storage.initItem('highscore', 0, 'int');
    this.game.storage.initItem('coins', 0, 'int');
    this.game.storage.initItem('currentSkin', 0, 'int');

    // Start preload state
    this.scene.start('PreloadState');
    
  }

  // resize(gameSize, baseSize, displaySize, resolution) {
  //   const width = gameSize.width;
  //   const height = gameSize.height;
    
  //   this.cameras.resize(width, height);

  //   // Adjust game objects based on the new size
  //   this.children.list.forEach(child => {
  //     if (child.resize) {
  //       child.resize(width, height);
  //     }
  //   });
  // }

}

export default BootState;
