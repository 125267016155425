import Phaser from 'phaser';
import pipoya_map from '../assets/map1.json';
import tiles1 from '../assets/40x40/pipo-map001.png';
import tiles2 from '../assets/40x40/pipo-map001_at.png';
import hero1 from '../assets/heroes/Male 01-2.png';

import bat01 from '../assets/monsters/pipo-enemy001.png';
import bat02 from '../assets/monsters/pipo-enemy001a.png';
import bat03 from '../assets/monsters/pipo-enemy001b.png';
import snake01 from '../assets/monsters/pipo-enemy003.png';
import snake02 from '../assets/monsters/pipo-enemy003a.png';
import snake03 from '../assets/monsters/pipo-enemy003b.png';
import treant01 from '../assets/monsters/pipo-enemy006.png';
import treant02 from '../assets/monsters/pipo-enemy006a.png';
import treant03 from '../assets/monsters/pipo-enemy006b.png';
import shroom01 from '../assets/monsters/pipo-enemy008.png';
import shroom02 from '../assets/monsters/pipo-enemy008a.png';
import shroom03 from '../assets/monsters/pipo-enemy008b.png';
import slime01 from '../assets/monsters/pipo-enemy009.png';
import slime02 from '../assets/monsters/pipo-enemy009a.png';
import slime03 from '../assets/monsters/pipo-enemy009b.png';
import zombie01 from '../assets/monsters/pipo-enemy011.png';
import zombie02 from '../assets/monsters/pipo-enemy011a.png';
import zombie03 from '../assets/monsters/pipo-enemy011b.png';
import ogre01 from '../assets/monsters/pipo-enemy015.png';
import ogre02 from '../assets/monsters/pipo-enemy015a.png';
import ogre03 from '../assets/monsters/pipo-enemy015b.png';
import hatchling01 from '../assets/monsters/pipo-enemy016.png';
import hatchling02 from '../assets/monsters/pipo-enemy016a.png';
import hatchling03 from '../assets/monsters/pipo-enemy016b.png';
import knight01 from '../assets/monsters/pipo-enemy018.png';
import knight02 from '../assets/monsters/pipo-enemy018a.png';
import knight03 from '../assets/monsters/pipo-enemy018b.png';
import ogre11 from '../assets/monsters/pipo-enemy019.png';
import ogre12 from '../assets/monsters/pipo-enemy019a.png';
import ogre13 from '../assets/monsters/pipo-enemy019b.png';
import cyclops01 from '../assets/monsters/pipo-enemy020.png';
import cyclops02 from '../assets/monsters/pipo-enemy020a.png';
import cyclops03 from '../assets/monsters/pipo-enemy020b.png';
import dragon01 from '../assets/monsters/pipo-enemy021.png';
import dragon02 from '../assets/monsters/pipo-enemy021a.png';
import dragon03 from '../assets/monsters/pipo-enemy021b.png';
import phoenix01 from '../assets/monsters/pipo-enemy022.png';
import phoenix02 from '../assets/monsters/pipo-enemy022a.png';
import phoenix03 from '../assets/monsters/pipo-enemy022b.png';
import cerberus01 from '../assets/monsters/pipo-enemy023.png';
import cerberus02 from '../assets/monsters/pipo-enemy023a.png';
import cerberus03 from '../assets/monsters/pipo-enemy023b.png';
import gargoyle01 from '../assets/monsters/pipo-enemy024.png';
import gargoyle02 from '../assets/monsters/pipo-enemy024a.png';
import gargoyle03 from '../assets/monsters/pipo-enemy024b.png';
import gargoyle11 from '../assets/monsters/pipo-enemy026.png';
import gargoyle12 from '../assets/monsters/pipo-enemy026a.png';
import gargoyle13 from '../assets/monsters/pipo-enemy026b.png';
import spider01 from '../assets/monsters/pipo-enemy027.png';
import spider02 from '../assets/monsters/pipo-enemy027a.png';
import spider03 from '../assets/monsters/pipo-enemy027b.png';
import scorpion01 from '../assets/monsters/pipo-enemy028.png';
import scorpion02 from '../assets/monsters/pipo-enemy028a.png';
import scorpion03 from '../assets/monsters/pipo-enemy028b.png';
import treasureChest01 from '../assets/monsters/pipo-enemy032.png';
import treasureChest02 from '../assets/monsters/pipo-enemy032a.png';
import treasureChest03 from '../assets/monsters/pipo-enemy032b.png';
import golem01 from '../assets/monsters/pipo-enemy033.png';
import golem02 from '../assets/monsters/pipo-enemy033a.png';
import golem03 from '../assets/monsters/pipo-enemy033b.png';
import chicken01 from '../assets/monsters/pipo-enemy036.png';
import chicken02 from '../assets/monsters/pipo-enemy036a.png';
import chicken03 from '../assets/monsters/pipo-enemy036b.png';
import dragon11 from '../assets/monsters/pipo-enemy044b.png';
import dragon12 from '../assets/monsters/pipo-enemy044c.png';
import dragon13 from '../assets/monsters/pipo-enemy044b.png';
import dragon14 from '../assets/monsters/pipo-enemy044e.png';

import boss01 from '../assets/monsters/pipo-boss001.png';
import boss02 from '../assets/monsters/pipo-boss002.png';
import boss03 from '../assets/monsters/pipo-boss003.png';
import boss04 from '../assets/monsters/pipo-boss004.png';


import gem01 from '../assets/gems/spr_coin_ama.png';
import gem02 from '../assets/gems/spr_coin_azu.png';
import gem03 from '../assets/gems/spr_coin_roj.png';

import ringOfFire from '../assets/skills/ringOfFire.png';
import laser1 from '../assets/skills/Laser_01.png';
import icicle from '../assets/skills/icicle.png';
import shuriken01 from '../assets/skills/Shuriken_01.PNG';
import tornado01 from '../assets/skills/Tornado_01.PNG'
import spiritBall01 from '../assets/skills/SpiritBall_01.PNG'
import boomerang01 from '../assets/skills/Boomerang_01.PNG'
import superStar01 from '../assets/skills/superStar_01.PNG'

import boomerangIcon from '../assets/icons/boomerang_icon.PNG'
import laserIcon from '../assets/icons/laser_icon.png'
import icicleIcon from '../assets/icons/icicle_icon.PNG'
import shurikenIcon from '../assets/icons/shuriken_icon.PNG'
import spiritBall_icon from '../assets/icons/spiritBall_icon.PNG'
import ringOfFireIcon from '../assets/icons/ringOfFire_icon.PNG'
import tornadoIcon from '../assets/icons/tornado_icon.PNG'

import silentIcon from '../assets/ui/silent.png'
import soundIcon from '../assets/ui/sound.png'

import bard_bgm from '../assets/sounds/bard_bgm.mp3'
import monsterHitSound from '../assets/sounds/monster_hit.wav'
import collectGemSound from '../assets/sounds/collect_gem.wav'

export default class PreloadState extends Phaser.Scene {
    constructor() {
        super({ key: 'PreloadState' });
    }
    preload() {
        // Create loading elements
        this.createLoadingElements();

        // Preload assets
        this._loadAssets();

        // Add resize event listener
        this.scale.on('resize', this.onResize, this);
    }

    createLoadingElements() {
        const { width, height } = this.cameras.main;

        // Create a loading bar and related elements
        this.progressBox = this.add.graphics();
        this.progressBox.fillStyle(0x222222, 0.8);
        this.progressBox.fillRect(width / 4, height / 2 - 30, width / 2, 50);

        this.progressBar = this.add.graphics();

        this.loadingText = this.add.text(width / 2, height / 2 - 50, 'Loading...', {
            fontSize: '20px',
            color: '#ffffff',
        }).setOrigin(0.5);

        this.percentText = this.add.text(width / 2, height / 2, '0%', {
            fontSize: '18px',
            color: '#ffffff',
        }).setOrigin(0.5);

        this.assetText = this.add.text(width / 2, height / 2 + 50, '', {
            fontSize: '16px',
            color: '#ffffff',
        }).setOrigin(0.5);

        // Update progress bar during loading
        this.load.on('progress', (value) => {
            this.percentText.setText(`${Math.floor(value * 100)}%`);
            this.progressBar.clear();
            this.progressBar.fillStyle(0xffffff, 1);
            this.progressBar.fillRect(
                width / 4 + 10,
                height / 2 - 20,
                (width / 2 - 20) * value,
                30
            );
        });

        // Clear elements when loading completes
        this.load.on('complete', () => {
            this.progressBar.destroy();
            this.progressBox.destroy();
            this.loadingText.destroy();
            this.percentText.destroy();
            this.assetText.destroy();
        });
    }
    onResize(gameSize) {
        const { width, height } = gameSize;
    
        // Ensure all elements are initialized
        if (!this.progressBox || !this.loadingText || !this.percentText || !this.progressBar) {
            console.error('Resize attempted before elements were initialized.');
            return;
        }
    
        // Update positions of texts
        this.loadingText.setPosition(width / 2, height / 2 - 50);
        this.percentText.setPosition(width / 2, height / 2);
        this.assetText.setPosition(width / 2, height / 2 + 50);
    
        // Redraw progress box
        this.progressBox.clear();
        this.progressBox.fillStyle(0x222222, 0.8);
        this.progressBox.fillRect(width / 4, height / 2 - 30, width / 2, 50);
    
        // Recalculate and redraw progress bar
        const progressValue = parseFloat(this.percentText.text) / 100 || 0;
        this.progressBar.clear();
        this.progressBar.fillStyle(0xffffff, 1);
        this.progressBar.fillRect(
            width / 4 + 10,
            height / 2 - 20,
            (width / 2 - 20) * progressValue,
            30
        );
    }
    
    _loadAssets() {
        // Load assets
        this.load.image('tiles', tiles1);
        this.load.image('tiles_at', tiles2);
        this.load.tilemapTiledJSON('pipoya_map', pipoya_map);
    
        // Load hero sprite
        this.load.spritesheet('hero', hero1, {
            frameWidth: 32,
            frameHeight: 32,
        });
    
        // Monster key-to-file mapping
        const monsters = {
            bat01: bat01,
            bat02: bat02,
            bat03:bat03,
            snake01: snake01,
            snake02: snake02,
            snake03: snake03,
            treant01: treant01,
            treant02: treant02,
            treant03: treant03,
            shroom01: shroom01,
            shroom02: shroom02,
            shroom03: shroom03,
            slime01: slime01,
            slime02: slime02,
            slime03: slime03,
            zombie01: zombie01,
            zombie02: zombie02,
            zombie03: zombie03,
            ogre01: ogre01,
            ogre02: ogre02,
            ogre03: ogre03,
            hatchling01: hatchling01,
            hatchling02: hatchling02,
            hatchling03: hatchling03,
            knight01: knight01,
            knight02: knight02,
            knight03: knight03,
            ogre11: ogre11,
            ogre12: ogre12,
            ogre13: ogre13,
            cyclops01: cyclops01,
            cyclops02: cyclops02,
            cyclops03: cyclops03,
            dragon01: dragon01,
            dragon02: dragon02,
            dragon03: dragon03,
            phoenix01: phoenix01,
            phoenix02: phoenix02,
            phoenix03: phoenix03,
            cerberus01: cerberus01,
            cerberus02: cerberus02,
            cerberus03: cerberus03,
            gargoyle01: gargoyle01,
            gargoyle02: gargoyle02,
            gargoyle03: gargoyle03,
            gargoyle11: gargoyle11,
            gargoyle12: gargoyle12,
            gargoyle13: gargoyle13,
            spider01: spider01,
            spider02: spider02,
            spider03: spider03,
            scorpion01: scorpion01,
            scorpion02: scorpion02,
            scorpion03: scorpion03,
            treasureChest01: treasureChest01,
            treasureChest02: treasureChest02,
            treasureChest03: treasureChest03,
            golem01: golem01,
            golem02: golem02,
            golem03: golem03,
            chicken01: chicken01,
            chicken02: chicken02,
            chicken03: chicken03,
            dragon11: dragon11,
            dragon12: dragon12,
            dragon13: dragon13,
            dragon14: dragon14,
            boss01: boss01,
            boss02: boss02,
            boss03: boss03,
            boss04: boss04
        };
    
        // Load monsters dynamically
        Object.entries(monsters).forEach(([key, path]) => {
            this.load.image(key, path);
        });
        this.load.image('boomerang_icon', boomerangIcon);
        this.load.image('laser_icon', laserIcon);
        this.load.image('icicle_icon', icicleIcon);
        this.load.image('shuriken_icon', shurikenIcon);
        this.load.image('spiritBall_icon', spiritBall_icon);
        this.load.image('ringOfFire_icon', ringOfFireIcon);
        this.load.image('tornado_icon', tornadoIcon);

        // Load skills
        this.load.spritesheet('ringOfFire', ringOfFire, {
            frameWidth: 96,
            frameHeight: 96,
            endFrame: 27,
        });
         this.load.image('laser', laser1);
         this.load.image('shuriken01',shuriken01);
         this.load.spritesheet('icicle', icicle,{
            frameWidth: 64,
            frameHeight: 64,
            endFrame: 30,
        });
        this.load.spritesheet('tornado01', tornado01,{
            frameWidth: 32,
            frameHeight: 32,
            endFrame: 4,
        });
        this.load.spritesheet('boomerang01', boomerang01,{
            frameWidth: 24,
            frameHeight: 24,
            endFrame: 8,
        });
        this.load.spritesheet('superStar01', superStar01,{
            frameWidth: 24,
            frameHeight: 24,
            endFrame: 8,
        });

        this.load.spritesheet('spiritBall01', spiritBall01,{
            frameWidth: 32,
            frameHeight: 32,
            endFrame: 8,
        });

        this.load.image('shuriken01', shuriken01);

        // Load gems
        this.load.spritesheet('gem01', gem01, {
            frameWidth: 16,
            frameHeight: 16,
        });
        this.load.spritesheet('gem02', gem02, {
            frameWidth: 16,
            frameHeight: 16,
        });this.load.spritesheet('gem03', gem03, {
            frameWidth: 16,
            frameHeight: 16,
        });

        this.load.image('silentIcon', silentIcon);
        this.load.image('soundIcon', soundIcon);
    
        // Load audio files (sounds and background music)
        this.load.audio('bard_bgm', bard_bgm);
        this.load.audio('monsterHitSound', monsterHitSound);
        this.load.audio('collectGemSound', collectGemSound);

    }
    
    create() {
        const resizeIcons = (key) => {
            const icon = this.add.image(0, 0, key).setDisplaySize(50, 50); // Set to uniform size
            icon.destroy(); // Remove it from the scene after resizing
        };

        // Resize skill icons
        const icons = [
            'boomerang_icon',
            'laser_icon',
            'icicle_icon',
            'shuriken_icon',
            'spiritBall_icon',
            'ringOfFire_icon',
            'tornado_icon',
        ];

        icons.forEach(resizeIcons);

        this.anims.create({
            key: 'tornado-spin',
            frames: this.anims.generateFrameNumbers('tornado01', { start: 0, end:3 }), // Adjust start and end frame
            frameRate: 10,  // Adjust the speed of the animation
            repeat: -1,     // Loop the animation infinitely
        });      
        this.anims.create({
            key: 'spiritballAnim',
            frames: this.anims.generateFrameNumbers('spiritBall01', { start: 0, end:3 }), // Adjust start and end frame
            frameRate: 10,  // Adjust the speed of the animation
            repeat: -1,     // Loop the animation infinitely
        });        
        this.anims.create({
            key: 'boomerangSpin',
            frames: this.anims.generateFrameNumbers('boomerang01', { start: 0, end:7 }), // Adjust start and end frame
            frameRate: 10,  // Adjust the speed of the animation
            repeat: -1,     // Loop the animation infinitely
        });        
        this.anims.create({
            key: 'superStarSpin',
            frames: this.anims.generateFrameNumbers('superStar01', { start: 0, end:7 }), // Adjust start and end frame
            frameRate: 10,  // Adjust the speed of the animation
            repeat: -1,     // Loop the animation infinitely
        });        


        // Transition to the GameState when assets are loaded
        this.scene.start('MainMenuState');
    }
}
